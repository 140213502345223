import { Grid } from '@mui/material'
import React from 'react'
import { getFSInformationsListConfig, MultipleCards, UniqueCard } from '../FsLineChart'
import { useAppSelector } from '../../../../../../redux/hooks'
import ChartBlock from '../../../../../../components/features/ChartBlock'
import { Units } from '../../../../../../redux/global/globalTypes'

const FsGBHTopFlop = () => {
    const { products } = useAppSelector((state) => state.global.fsDashboardData)
    const { unit } = useAppSelector((state) => state.global)
    const { name } = useAppSelector((state) => state.global.env)
    const tooltipTop = "Croissance maximale enregistrée par rapport à l’année précédente sur la même période."
    const tooltipFlop = "Recul maximal (ou croissance minimale) enregistré par rapport à l'année précédente sur la même période."

    return (
        <ChartBlock
            title={`TOP / FLOP - Références JPB (${Units.uvc})`}
            link={`/food-service/${name}/références`}
        >
            <Grid container wrap="nowrap" height="100%">
                {products.length > 1 ? (
                    <MultipleCards
                        top={products[0]}
                        flop={products[products.length - 1]}
                        informationsListConfigTop={getFSInformationsListConfig(products[0])}
                        informationsListConfigFlop={getFSInformationsListConfig(products[products.length - 1])}
                        unit={Units.uvc}
                        tooltipTop={tooltipTop}
                        tooltipFlop={tooltipFlop}
                    />
                ) : (
                    <UniqueCard data={products[0]}
                        unit={unit}
                        informationsListConfig={getFSInformationsListConfig(products[0])} tooltipTop={tooltipTop} />
                )}
            </Grid>
        </ChartBlock>
    )
}

export default FsGBHTopFlop
