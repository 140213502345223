import React, { useState } from "react"
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid"
import { Env, EnvName, EnvStatus, UsersRole, UsersStatus } from "../../../../redux/user/userTypes"
import { PrimaryText, SecondaryText } from "../../../../components/ui/Text"
import { BLACK, BRIC, PRIMARY } from "../../../../components/ui/Colors"
import { SpecificChip } from "../../../../components/ui/SpecificChip"
import { Grid, } from "@mui/material"
import { ModifyUserMenu } from "./ModifyUser"
import { getModulesNames } from "./InvitationEnvStep"
import { Member } from "../../../../redux/companies/companiesTypes"
import { ActionMenu } from "../../../../components/ui/ActionMenu"
import MoreVertIcon from '@mui/icons-material/MoreVert'

const statusToDisplay = (status: UsersStatus): string => {
    switch (status) {
        case UsersStatus.ACTIVATED:
            return "Actif"
        case UsersStatus.PENDING:
            return "En attente de création de compte"
        case UsersStatus.DEACTIVATED:
            return "Désactivé"
        default:
            return "Actif"
    }
}

const roleToDisplay = (role: UsersRole): string => {
    switch (role) {
        case UsersRole.ADMIN:
            return "Admin"
        case UsersRole.USER:
            return "Utilisateur"
        default:
            return "Utilisateur"
    }
}

export const MembersDataGridColumns = (): GridColDef[] => {
    const [open, setOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [fieldSelected, setFieldSelected] = useState<Member | undefined>()
    const handleClick = (e: React.MouseEvent, value: any) => {
        e.stopPropagation()
        e.preventDefault()
        if (open) {
            setAnchorEl(null)
            setOpen(false)
            setFieldSelected(null)
        } else {
            setAnchorEl(e.currentTarget)
            setOpen(true)
            setFieldSelected(value)
        }
    }
    return [
        {
            field: 'firstname',
            headerName: 'Collaborateur',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {

                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {

                const collaborator = params.row.status !== UsersStatus.PENDING ? `${params.row.firstname} ${params.row.lastname}` : `${params.row.email}`
                return (
                    <SecondaryText style={{ fontSize: "1.7vmin" }} color={BLACK}>{collaborator}</SecondaryText>
                )
            },
        }, {
            field: 'role',
            headerName: 'Rôle',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                const color = params.row.role === UsersRole.ADMIN ? "#6B95FF" : BLACK

                return (
                    <SecondaryText style={{ fontSize: "1.7vmin" }} color={color}>{roleToDisplay(params.value)}</SecondaryText>
                )
            },
        },
        {
            field: 'env',
            headerName: 'Espaces à disposition',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container
                        p={2}
                        sx={{
                            justifyContent: 'flex-start'
                        }}>
                        {getEnvNames(params.value).map((el, index) => {
                            return (
                                <Grid p={0.1} key={index} >
                                    <SpecificChip
                                        pointer={false}
                                        label={el}
                                        color={PRIMARY}
                                    />
                                </Grid>)
                        })}
                    </Grid>

                )
            },
        },
        {
            field: 'modules',
            headerName: 'Modules à disposition',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container
                        p={2}
                        sx={{
                            justifyContent: 'flex-start'
                        }}>
                        {getModulesNames(params.value).map((el, index) => {
                            return (
                                <Grid p={0.1} key={index} >
                                    <SpecificChip
                                        pointer={false}
                                        label={el}
                                        color={PRIMARY}
                                    />
                                </Grid>)
                        })}
                    </Grid>

                )
            },
        },
        {
            field: 'status',
            headerName: 'Statut',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                const color = params.row.status === UsersStatus.PENDING ? BRIC : BLACK

                return (
                    <SecondaryText style={{ fontSize: "1.7vmin" }} color={color}>{statusToDisplay(params.value)}</SecondaryText>
                )
            },
        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {

                const statusDeactivated = fieldSelected ? fieldSelected.status === UsersStatus.DEACTIVATED : false

                return (
                    <Grid>
                        <MoreVertIcon id="MoreIcon" sx={{ color: BLACK, cursor: 'pointer' }} onClick={(e) => handleClick(e, params.row)} />

                        <ActionMenu
                            open={open}
                            anchorEl={anchorEl}
                            onClose={(e) => {
                                handleClick(e, params.row)
                            }}
                            children={<ModifyUserMenu values={fieldSelected} userStatusDeactivated={statusDeactivated} closeMenu={setOpen} />}
                        />
                    </Grid>)
            },

        },
    ]
}

export const getEnvNames = (list: Env[]) => {
    return list.filter((el) => el.status === EnvStatus.ACTIVATED && el.name !== EnvName.SETTING).map((env) => env.name)
}

export const roles = [
    { label: 'Utilisateur', value: UsersRole.USER },
    {
        label: `Administrateur : peut gérer les utilisateurs de l'entreprise et inviter d’autres membres à rejoindre l’espace KaryonFood.`,
        value: UsersRole.ADMIN,
    },
]

