import { Grid } from "@mui/material"
import { DataTableComponent } from "../../../DataTableComponent"
import React, { useState } from "react"
import { DrillDownType } from "../../../../../components/features/drillDowns/ProductsDrillDownContent"
import { GridCellParams } from "@mui/x-data-grid"
import { GBHProductsColumns, GBHProductsHeadTabColumns } from "../../utils"
import DrillDown from "../../../../../components/features/drillDowns/DrillDown"
import { useAppSelector } from "../../../../../redux/hooks"
import { ProductsChartAndTwoList } from "../../../../../redux/fsProducts/fsProductsTypes"

export const ProductsPerBrand = () => {

    const [isDrilldownProductsOpen, setDrilldownProductsOpen] = useState(false)
    const [fieldProductsSelected, setFieldProductsSelected] =
        useState<GridCellParams<any>>()
    const [productsValue, setProductsValue] = useState(0)
    const products = useAppSelector((state) => state.fsProducts.products as unknown as ProductsChartAndTwoList)

    const handleCloseProducts = () => {
        setDrilldownProductsOpen(false)
    }

    return <Grid height={'100%'}>
        <Grid height="45vh" mb="2vh">
            <DataTableComponent
                rows={products.brandsList}
                columns={GBHProductsHeadTabColumns("brand")}
                getRowId={(row) => row.brand}
                hasDrillDown={false}
            />
        </Grid>
        <Grid height="45vh">
            <DrillDown
                type={DrillDownType.PRODUCTSGBH}
                open={isDrilldownProductsOpen}
                title={fieldProductsSelected && `${fieldProductsSelected.row.product}`}
                value={productsValue}
                setValue={setProductsValue}
                row={fieldProductsSelected}
                handleClose={handleCloseProducts}
            />
            <DataTableComponent
                type={DrillDownType.PRODUCTSGBH}
                setOpen={setDrilldownProductsOpen}
                setFieldSelected={setFieldProductsSelected}
                setValue={setProductsValue}
                rows={products.productsList}
                columns={GBHProductsColumns("brand")}
                getRowId={(row) => row.id}
            />
        </Grid>
    </Grid >
}