import { Box, Grid } from '@mui/material'
import { Form, FormikBag, FormikProps, withFormik } from 'formik'
import React from 'react'
import { object, string } from 'yup'
import { PrimaryButton } from '../../../../../components/ui/Button'
import { EnhancedField, TextInput } from '../../../../../components/ui/Inputs'
import { StepperData } from '../../../../../redux/stepper/stepperTypes'
import { Inputs } from './SignUpAccount'
import { StepContentComponent } from '../stepper/Stepper'

interface FormValues {
    lastname: string
    firstname: string
    phoneNumber: string
}

interface FormProps {
    onSubmit: (values: FormValues) => void
    initialValues: FormValues
}

const inputs: Inputs[] = [
    { type: 'text', name: 'lastname', label: 'Nom' },
    { type: 'text', name: 'firstname', label: 'Prénom' },
    {
        type: 'tel',
        name: 'phoneNumber',
        label: 'Numéro de téléphone (facultatif)',
    },
]

const PureSignUpUserForm = (
    props: FormikProps<FormValues> & FormProps
): JSX.Element => {
    return (
        <Form>
            <Grid container direction="column">
                {inputs.map((input, index) => (
                    <EnhancedField
                        type={input.type}
                        name={input.name}
                        label={input.label}
                        autoFocus={index === 0}
                        margin="normal"
                        component={TextInput}
                        key={index}
                    />
                ))}
                <Box mt={5} width="100%">
                    <PrimaryButton fullWidth type="submit">
                        Suivant
                    </PrimaryButton>
                </Box>
            </Grid>
        </Form>
    )
}

const ControlledSignUpCompanyForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props: FormProps) => ({
        lastname: props.initialValues.lastname,
        firstname: props.initialValues.firstname,
        phoneNumber: props.initialValues.phoneNumber,
    }),
    validationSchema: () => {
        return object().shape({
            lastname: string().required('Le champ Nom est obligatoire'),
            firstname: string().required('Le champ Prénom  est obligatoire'),
            phoneNumber: string()
                .matches(
                    /[0-9\.\-\s+\/()]+/g,
                    'Les lettres ne sont pas autorisées'
                )
                .min(
                    10,
                    'Le numéro de téléphone doit contenir au moins 10 chiffres'
                )
                .optional(),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureSignUpUserForm)

const SignUpUser = ({
    getData,
    updateData,
    handleNext,
}: StepContentComponent) => {
    const initialValues = getData() as unknown as FormValues

    const handleSubmit = (values: FormValues) => {
        updateData(values as unknown as StepperData)
        handleNext()
    }

    return (
        <Grid container direction="column">
            <ControlledSignUpCompanyForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
        </Grid>
    )
}

export default SignUpUser
