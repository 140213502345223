import React, { useState } from 'react'
import HistogramLabel from './HistogramLabel'
import { Box, Grid } from '@mui/material'
import { SquareColor } from '../../../../ui/ColorSquare'

type LegendState = Array<{ type: number }>

export const dashboardLabels = [
    {
        id: 0,
        title: 'Volumes A-1',
        backgroundColor: '#ADB2D3',
    },
    {
        id: 1,
        title: 'Volumes A',
        backgroundColor: '#3A4D87',
    }
]

export const storesPageLabels = [
    {
        id: 0,
        title: 'PDV A-1',
        backgroundColor: '#ADB2D3',
    },
    {
        id: 1,
        title: 'PDV A',
        backgroundColor: '#3A4D87',
    }
]


const HistogramLabels = ({ chart, labels }: { chart: any, labels: any }) => {
    const [legend, setLegend] = useState<LegendState>([])
    const [datasets, setDatasets] = useState<number[]>([])

    const showDataset = (menu: number, id: number) => {
        setLegend(
            legend.filter((el) => {
                if (el.type === menu) {
                    return false
                }
                return true
            })
        )
        setDatasets(datasets.filter((elem, pos) => pos !== id))
        chart.show(menu)
    }

    const hideDataset = (menu: number) => {
        setLegend((current) => [...current, { type: menu }])

        setDatasets([...datasets, menu])
        chart.hide(menu)
    }

    const HandleToogleDatasets = (e: React.MouseEvent, menu: number) => {
        e.preventDefault()
        const el = datasets.indexOf(menu)

        if (el >= 0) {
            showDataset(menu, el)
        } else if (datasets.length === 0) {
            hideDataset(menu)
        }
    }

    return (
        <Grid container>
            <Grid container justifyContent={'center'}>
                {labels.map((label, index) => {
                    return (
                        <Box key={index} >
                            <Grid container alignItems="center" ml="2vw">
                                <HistogramLabel
                                    hide={
                                        legend.findIndex(
                                            (el) => el.type === index
                                        ) >= 0
                                    }
                                    layer={index}
                                    toggleDataSets={HandleToogleDatasets}
                                    label={label.title}
                                    color={label.backgroundColor}
                                    size={SquareColor.SM}
                                />
                            </Grid>
                        </Box>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default HistogramLabels
