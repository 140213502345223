import React, { useEffect, useState, useSyncExternalStore } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeComponent } from './TreeComponent'
import { useAppSelector } from '../../../../../redux/hooks'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../../helpers/ExternalStore'
import { formateLegend } from '../../../../../components/features/foodService/dashboard/utils/formateProductsData'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { Chart, ProductsChart, ProductsChartGBH } from '../../../../../redux/fsProducts/fsProductsTypes'
import { PrimaryText } from '../../../../../components/ui/Text'
import { BLACK } from '../../../../../components/ui/Colors'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export const backgroundColor = [
    '#3A4D87',
    '#B9C7F9',
    '#EF9BFD',
    '#F7EAA6',
    '#F7B0A6',
    '#FACE5D',
    '#A17D5B',
    '#F06B13',
    '#D9CA07',
    '#8FFC54',
    '#FAB0AC',
    '#553A87',
    '#FA374C',
    '#D9A507',
    '#E8DBD1',
    '#8CA0FF',
    '#968744',
    '#F5C8A2',
    '#77C8A2',
    '#965944',
    '#D5EDD6',
    '#BD5944',
    '#BDCBCB',
    '#EDE5D5',
    '#259AB3',
    '#1911E0',
    '#E03400',
    '#F28655',
    '#C77F77',
    '#C4BC1A',
    '#E1F0C5',
    '#E6F581',
    '#59C9AA',
    '#F7562A',
    '#FAB9F8',
]

const createDatasets = (products: Chart[], filter?: string) => {
    let productsAfterFilter = products
    if (filter) {
        productsAfterFilter = products.filter((product) => product.brand === filter)

    }

    return productsAfterFilter.map((product, index) => {
        const color = backgroundColor[index % 33]

        return {
            name: product.name,
            ...product.brand && { brand: product.brand },
            data: [null, ...product.data, null],
            borderColor: color,
            backgroundColor: color,
        }
    })
}

export const LineChartProduct = ({ chart, period, legendDepth }: { chart: ProductsChart | ProductsChartGBH, period: string[], legendDepth: number }) => {
    const [datasets, setDatasets] = useState(createDatasets(chart.chart))

    const { unit } = useAppSelector((state) => state.global)
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )
    const chartRef = React.useRef()
    const [clickedAnywhere, setClickedAnywhere] = useState(true)

    useEffect(() => {
        document.body.addEventListener('click', () => {
            setClickedAnywhere(false)
        })
    }, [])

    const options = {
        animation: clickedAnywhere,
        maintainAspectRatio: false,
        responsive: true,
        pointBorderWidth: height * 0.005,
        borderWidth: height * 0.004,
        scales: {
            x: {
                ticks: {
                    font: { size: height * 0.015 },
                },
            },
            y: {
                ticks: {
                    font: { size: height * 0.015 },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any, tooltipItem: any) => {
                        const sells = Math.round(model.raw)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        return `${model.dataset.name} : ${sells} ${unit}`
                    },
                },
            },
        },
    }

    const formatedLegend = formateLegend(period)

    const data = {
        labels: [null, ...formatedLegend, null],
        datasets: datasets,
    }

    const handleChange = (e: string) => {
        const data = createDatasets(chart.chart, e)
        setDatasets(data)
    }
    return (
        <Grid container wrap="nowrap" alignItems="flex-start">
            <Grid
                sx={{
                    height: '34vh',
                    width: '40vh',
                    padding: '1vw',
                }}
                flex={3}
            >
                <Line ref={chartRef} options={options as any} data={data} />
            </Grid>
            <Grid
                flex={1}
                mt="1vw"
                mb="1vw"
                sx={{
                    height: '34vh',
                    '& .MuiTreeItem-label': { fontSize: '1.6vmin !important' },
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                        width: 1,
                    },
                }}
            >
                {legendDepth === 1 &&
                    <FormControl>

                        <RadioGroup
                            onChange={(e) => { handleChange(e.target.value) }}
                            name="radio-buttons-group"
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    height: '2vh',
                                    width: '2vh',
                                    marginRight: 1,
                                },
                            }}
                        >
                            {chart.legend.map((element, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={element.brand}
                                    control={<Radio />}
                                    label={
                                        <PrimaryText color={BLACK}>
                                            {element.brand}
                                        </PrimaryText>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </FormControl >}
                {legendDepth === 3 && chart.legend.map((family, index) => (
                    <TreeView
                        sx={{
                            width: '100%',
                            '& .MuiTreeItem-label': {
                                ml: 0.5,
                            },
                        }}
                        key={index}
                        aria-label="data"
                        defaultCollapseIcon={
                            <ExpandMoreIcon
                                sx={{
                                    width: '2.5vw',
                                    height: '2.5vh',
                                }}
                            />
                        }
                        defaultExpandIcon={
                            <ChevronRightIcon
                                sx={{ width: '2.5vw', height: '2.5vh' }}
                            />
                        }
                    >
                        <TreeItem
                            key={family.family}
                            nodeId={index.toString()}
                            label={family.family}
                            sx={{ maxHeight: '100%', width: '90%' }}
                        >
                            {family.subFamily.map(
                                (subFamily, subIndex, arr) => {

                                    const start = arr[subIndex - 1]
                                        ? arr[subIndex - 1].products.length
                                        : 0


                                    return (
                                        <TreeComponent
                                            data={datasets}
                                            key={`${index}-${subIndex}`}
                                            nodeId={`${index}-${subIndex}`}
                                            name={subFamily.label}
                                            chart={chartRef.current}
                                            products={subFamily.products}
                                            start={start}
                                        />
                                    )
                                }
                            )}
                        </TreeItem>
                    </TreeView>
                ))}
            </Grid >
        </Grid >
    )
}
