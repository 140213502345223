import { Grid } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { LineChartProduct } from './ProductsPage/LineChartProduct'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { DataTableComponent } from '../../DataTableComponent'
import ChartBlock from '../../../../components/features/ChartBlock'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
    setDrillDownClients,
    setDrillDownWarehouses,
} from '../../../../redux/fsProducts/fsProductsSlice'
import { ProductItemFS, Products } from '../../../../redux/fsProducts/fsProductsTypes'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import DrillDown from '../../../../components/features/drillDowns/DrillDown'
import { Units } from '../../../../redux/global/globalTypes'
import { transformProductsList } from '../../../../components/features/drillDowns/WarehousesDrillDownContent'

const FsProductsPage = ({
    columns,
    displayToggle,
    lineChartTitle,
    drillDownType,
}: {
    columns: (hidden?: boolean, unit?: Units) => GridColDef[]
    displayToggle: boolean
    lineChartTitle: string
    drillDownType: DrillDownType
}) => {
    const [isOpen, setOpen] = useState(false)
    const [hidden, setHiden] = useState(displayToggle)
    const [fieldSelected, setFieldSelected] = useState<GridCellParams<ProductItemFS>>()
    const [value, setValue] = useState(0)
    const dispatch = useAppDispatch()
    const products = useAppSelector((state) => state.fsProducts.products as Products)


    const _setDrillDownClients = useCallback(() => {
        dispatch(setDrillDownClients([]))
    }, [dispatch])

    const _setDrillDownWarehouses = useCallback(() => {
        dispatch(setDrillDownWarehouses([]))
    }, [dispatch])

    const handleClose = () => {
        setOpen(false)
        _setDrillDownClients()
        _setDrillDownWarehouses()
    }

    const productsListLength = products.list.filter((el) => el.type === 'product').length

    const productsListTransformed = transformProductsList(products.list)

    return (
        <Grid
            container
            direction="column"
            sx={{
                height: '90vh',
                '& .MuiDataGrid-row:hover #tendency': {
                    visibility: 'visible',
                },
            }}
            p="1vw"
            flexWrap="nowrap"
        >
            <DrillDown
                type={drillDownType}
                open={isOpen}
                title={fieldSelected && `${fieldSelected.row.productName}`}
                value={value}
                setValue={setValue}
                row={fieldSelected}
                handleClose={handleClose}
            />

            <Grid item>
                {products && (
                    <ChartBlock title={lineChartTitle}>
                        <LineChartProduct chart={products.chart} period={products.period} legendDepth={3} />
                    </ChartBlock>
                )}
            </Grid>
            <Grid height="47vh" item mt="1vw">
                {products && (
                    <ChartBlock
                        title={`Liste des références (${productsListLength})`}
                    >
                        {/*          {displayToggle ? (
                                <PrimaryButton
                                    onClick={() => setHiden(!hidden)}
                                >
                                    Toggle VMM Columns
                                </PrimaryButton>
                            ) : (
                                <></>
                            )} */}
                        <DataTableComponent
                            type={drillDownType}
                            setOpen={setOpen}
                            setFieldSelected={setFieldSelected}
                            setValue={setValue}
                            rows={productsListTransformed}
                            columns={columns(hidden)}
                            getRowId={(row) => row.id}
                        />
                    </ChartBlock>
                )}
            </Grid>
        </Grid>
    )
}

export default FsProductsPage
