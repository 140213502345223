import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Grid, Tooltip } from '@mui/material'
import { GREEN, RED } from './Colors'
import { PrimaryText, SecondaryText } from './Text'
import NumberWithSpace from './NumberWithSpace'
import { roundIfNotZero } from '../../helpers/roundIfNotZero'

interface OwnProps {
    difference: number
    unit: string
    kpi?: boolean
    tooltip?: TooltipMessage
    notRoundable?: boolean
    evolution?: number
    isDoubleKpi?: boolean
}

export enum TooltipMessage {
    WAREHOUSES = `Ecart du nombre de dépôts par rapport à l’année précédente sur la même période.`,
    CUSTOMERS = `Ecart du nombre d'utilisateurs finaux par rapport à l’année précédente sur la même période.`,
    STORES = `Ecart du nombre de magasins par rapport à l’année précédente sur la même période.`,
    PRODUCTS = `Ecart du nombre de références par rapport à l’année précédente sur la même période.`,
    VOLUME = `Ecart de volume par rapport à l’année précédente sur la même période.`,
    VMM = `Ecart des ventes moyennes mensuelles (VMM) par rapport à l’année précédente sur la même période.`,
    SHARE_OF_TURNOVER = `Ecart de la part de CA par rapport à l’année précédente sur la même période.`,
    CATEGORIES = "Ecart du nombre de catégories produits par rapport à l'année précédente sur la même période.",
    CA = "Ecart de CA par rapport à l'année précédente sur la même période.",
    PDM_VOLUME = "Ecart de parts de marché par rapport à l'année précédente sur la même période",
    PDM_VALUE = "Ecart de parts de marché par rapport à l'année précédente sur la même période",
    DN = "Ecart de DN par rapport à l'année précédente sur la même période"
}

const Tendency = ({
    difference,
    unit,
    kpi,
    tooltip,
    notRoundable = false,
    evolution,
    isDoubleKpi = false
}: OwnProps) => {
    const isEqual = roundIfNotZero(difference) === '0'

    // Determine arrow and text color :
    const color = difference > 0 ? GREEN : RED

    // Determine wich arrow to display if needed
    const arrowTendency =
        difference > 0 ? (
            <ArrowUpwardIcon sx={{ color, height: '2vh' }} />
        ) : (
            <ArrowDownwardIcon sx={{ color, height: '2vh' }} />
        )

    const EvolutionText = isDoubleKpi ? SecondaryText : PrimaryText

    return (
        <Tooltip title={tooltip}>
            <Grid
                id="tendency"
                container
                wrap="nowrap"
                sx={
                    kpi
                        ? {
                            '& svg': { width: '1.2vw' },
                        }
                        : {
                            width: "auto",
                            '& svg': { width: '2vw' },
                        }

                }
                justifyContent="center"
                alignItems="center"
            >
                {!isEqual && arrowTendency}
                {isEqual ? (
                    <Grid container alignItems="baseline" >
                        <SecondaryText
                            style={{ height: '2vh', marginRight: '1vw' }}
                        >
                            =
                        </SecondaryText>
                    </Grid>

                ) : !kpi ? (
                    <Grid container>
                        <SecondaryText color={color}>
                            <NumberWithSpace
                                number={roundIfNotZero(difference, notRoundable)}
                            />
                            {`${unit}`}
                        </SecondaryText>
                    </Grid>
                ) : (
                    <Grid container alignItems="baseline">
                        <SecondaryText color={color}>
                            <NumberWithSpace
                                number={roundIfNotZero(
                                    difference,
                                    notRoundable
                                )}
                            />
                            {unit}
                        </SecondaryText>
                        {
                            (!evolution || evolution === 0) && (
                                <EvolutionText color={color} ml="0.5vw">{` (-%)`}</EvolutionText>
                            )
                        }
                        {
                            evolution !== 0 && (
                                <EvolutionText color={color} ml="0.5vw">{` (${roundIfNotZero(evolution, true)} %)`}</EvolutionText>
                            )
                        }
                    </Grid>
                )}
            </Grid>
        </Tooltip>
    )
}

export default Tendency
