import { DialogContent, Grid } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { date, object, ref } from 'yup'
import FilterSelect from '../filters/FilterSelect'
import { useAppSelector } from '../../../redux/hooks'
import { StepperData } from '../../../redux/stepper/stepperTypes'
import { PrimaryText } from '../../ui/Text'
import { DialogFooter } from '../Dialog/BasicFooterDialog'
import FilterCalendar from '../filters/FilterCalendar'
import { getPlaceholderDates, monthAsArray } from '../../../helpers/months'
import { BLACK } from '../../ui/Colors'
import { ExportComponentProps, StepExportConfig } from './ExportSetup'
import { EnvType } from '../../../redux/user/userTypes'
import Loading from '../screens/LoadingScreen'
import { FiltersItem, MenuType, getFiltersConfig } from '../filters/utils'
import InventoryIcon from '@mui/icons-material/Inventory'

interface FormValues {
    startDate: Date
    endDate: Date
    products: string[]
    stores: string[]
}

interface FormProps {
    activeValues: FormValues
    config: StepExportConfig
    step: number
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
    isLoading: boolean
    filtersConfig: FiltersItem[]
}

const PureRetailExportFilterStepForm = ({
    setFieldValue,
    submitForm,
    filtersConfig,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    const [modified, setModified] = useState(false)
    const [printedPeriod, setPrintedPeriod] = useState<string | null>(null)

    useEffect(() => {
        if (props.values.startDate && props.values.endDate) {
            const period = getPlaceholderDates(
                props.initialValues.startDate,
                props.initialValues.endDate
            )
            setPrintedPeriod(period)
        }
    }, [props.values])

    return (
        <Form style={{ width: '100%' }}>
            <DialogContent sx={{ height: '32vh' }}>
                {props.isLoading ? (
                    <Grid container alignItems="center" height="100%">
                        <Loading text={false} height="100%" />
                    </Grid>
                ) : (
                    <Grid>
                        <PrimaryText color={BLACK}>
                            Sélectionnez les champs que vous souhaitez filtrer :
                        </PrimaryText>
                        <Grid>
                            <Grid
                                container
                                style={{
                                    marginTop: '4vh',
                                    padding: 4,
                                }}
                            >
                                <PrimaryText width="30%">
                                    Mois et année :{' '}
                                </PrimaryText>
                                <Grid sx={{ flex: 1, width: '100%' }}>
                                    <FilterCalendar
                                        isModified={modified}
                                        placeholder={printedPeriod}
                                        setFieldValue={setFieldValue}
                                        setModified={setModified}
                                        start={props.values.startDate}
                                        end={props.values.endDate}
                                        errorEndDate={
                                            props.errors.endDate as string
                                        }
                                        errorStartDate={
                                            props.errors.startDate as string
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {filtersConfig.map((filter, index) => (
                                <Grid
                                    container
                                    style={{
                                        padding: 4,
                                    }}
                                    key={index}
                                >
                                    <PrimaryText width="30%">
                                        {filter.placeholder} :{' '}
                                    </PrimaryText>
                                    <FilterSelect
                                        name={filter.name}
                                        icon={filter.icon}
                                        placeholder={filter.placeholder}
                                        type={filter.type}
                                        values={props.values[filter.name]}
                                        setFieldValue={setFieldValue}
                                        key={filter.id}
                                        env={EnvType.RETAIl}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogFooter
                leftButtonFunc={(e) => {
                    props.config.handlePrevious()
                }}
                leftButtonText={'PRECEDENT'}
                rightButtonFunc={(e) => {
                    e.stopPropagation()

                    submitForm()
                }}
                rightButtonText={'SUIVANT'}
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        startDate: props.activeValues.startDate,
        endDate: props.activeValues.endDate,
        products: props.activeValues.products,
        stores: props.activeValues.stores,
        environment: EnvType.RETAIl,
    }),
    enableReinitialize: true,
    validationSchema: (props) => {
        const startMonth = props.activeValues.startDate.getMonth()
        const startYear = props.activeValues.startDate.getFullYear()
        const endMonth = props.activeValues.endDate.getMonth()
        const endYear = props.activeValues.endDate.getFullYear()
        return object().shape({
            startDate: date()
                .typeError('Entrez une date valide')
                .min(
                    props.activeValues.startDate,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de début'),
            endDate: date()
                .typeError('Entrez une date valide')
                .min(
                    ref('startDate'),
                    'Sélectionnez une date plus ancienne que celle de début'
                )
                .max(
                    props.activeValues.endDate,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de fin'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureRetailExportFilterStepForm)

const RetailExportFiltersStep = ({ step, config }: ExportComponentProps) => {
    const { isLoading } = useAppSelector((state) => state.global)
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const { env } = useAppSelector((state) => state.global)
    const handleSubmit = (values: FormValues) => {
        config.updateData(values as unknown as StepperData)
        config.handleNext()
    }

    const { retailFilters } = useAppSelector((state) => state.filters)
    const [filtersConfig, setFiltersConfig] = useState<FiltersItem[]>([
        {
            id: 0,
            name: 'products',
            icon: <InventoryIcon />,
            placeholder: 'Références',
            type: MenuType.PRODUCTS,
        },
    ])

    useEffect(() => {
        const keysFilters = retailFilters ? Object.keys(retailFilters) : []
        const config = getFiltersConfig(keysFilters, env.name)
        setFiltersConfig(config)
    }, [retailFilters])

    const activeValues = {
        startDate: new Date(activeRetailFilters.startDate),
        endDate: new Date(activeRetailFilters.endDate),
        products: activeRetailFilters.products,
        stores: activeRetailFilters.stores,
    }

    activeValues.startDate.setHours(0, 0, 0)
    activeValues.endDate.setHours(0, 0, 0)

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            activeValues={activeValues}
            isLoading={isLoading}
            filtersConfig={filtersConfig}
        />
    )
}

export default RetailExportFiltersStep
