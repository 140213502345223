import { Box, createTheme, ThemeProvider } from '@mui/material'
import React, { useSyncExternalStore } from 'react'
import {
    DataGrid,
    type GridColDef,
    frFR,
    GridCellParams,
} from '@mui/x-data-grid'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../helpers/ExternalStore'
import { DrillDownType } from '../../components/features/drillDowns/ProductsDrillDownContent'
import { LIGHT_BLUE, MEDIUM_GREY } from '../../components/ui/Colors'

export interface DataTableComponentProps {
    columns: GridColDef[]
    rows: any
    getRowId?: (row: any) => string
    type?: DrillDownType
    setOpen?: (state: boolean) => void
    setValue?: (state: number) => void
    setFieldSelected?: (state: any) => void
    hideFooter?: boolean
    navigate?: (row: any) => void
    hasDrillDown?: boolean
}
export const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    frFR
)

export enum Pages {
    PRODUCTS = 'products',
    CLIENTS = 'clients',
    WAREHOUSES = 'warehouses',
    DASHBOARD = 'dashboard',
}

enum Columns {
    PRODUCTS = 'products',
    CLIENTS = 'clients',
    WAREHOUSES = 'warehouses',
    WAREHOUSE = 'warehouse',
    STORES = 'stores',
    REFERENCE = 'references',
    PRODUCTS_EVOLUTION = 'differenceProducts',
    RETAIL_PRODUCTS_EVOLUTION = 'differenceReferences',
    CLIENTS_EVOLUTION = 'differenceClients',
    STORES_EVOLUTION = 'differenceStores',
    WAREHOUSES_EVOLUTION = 'differenceWarehouses',
}

export const DataTableComponent = ({
    type,
    rows,
    columns,
    getRowId,
    setValue,
    setFieldSelected,
    setOpen,
    hideFooter = false,
    hasDrillDown = true
}: DataTableComponentProps) => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    const checkColumnAndPageSelected = (
        type: DrillDownType,
        column: string
    ) => {
        switch (true) {
            case type === DrillDownType.PRODUCTS &&
                (column === Columns.WAREHOUSES ||
                    column === Columns.WAREHOUSES_EVOLUTION):
            case (type === DrillDownType.CLIENTS ||
                type === DrillDownType.WAREHOUSES ||
                type === DrillDownType.CLIENTSPF) &&
                (column === Columns.PRODUCTS ||
                    column === Columns.PRODUCTS_EVOLUTION):
            case (type === DrillDownType.STORES &&
                column === Columns.REFERENCE) ||
                column === Columns.RETAIL_PRODUCTS_EVOLUTION:
            case (type === DrillDownType.RETAILPRODUCTS &&
                column === Columns.STORES) ||
                column === Columns.STORES_EVOLUTION:
                return setValue(1)
            case (type === DrillDownType.CLIENTS ||
                type === DrillDownType.CLIENTSPF) &&
                (column === Columns.WAREHOUSES ||
                    column === Columns.WAREHOUSES_EVOLUTION):
            case (type === DrillDownType.PRODUCTS ||
                type === DrillDownType.WAREHOUSES) &&
                (column === Columns.CLIENTS ||
                    column === Columns.CLIENTS_EVOLUTION):
                return setValue(3)
            case type === DrillDownType.WAREHOUSESPF &&
                column === Columns.WAREHOUSE:
                return setValue(0)
            case type === DrillDownType.WAREHOUSESPF &&
                column !== Columns.WAREHOUSE:
                return setValue(1)
            default:
                return setValue(0)
        }
    }

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                '&:hover #textTooltip': {
                    visibility: 'visible',
                },
                '& .MuiDataGrid-columnHeaders': {
                    height: '4vh',
                    maxHeight: 'unset !important',
                    minHeight: 'unset !important',
                },
                '& .MuiDataGrid-columnHeader': {
                    padding: '0 1vw !important',
                    height: '100% !important',
                },
                '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator:last-child':
                {
                    display: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    justifyContent: 'center !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer:first-of-type': {
                    justifyContent: 'center !important',
                },
                '& div.MuiDataGrid-columnHeader:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1)':
                {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft:first-of-type': {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft': {
                    justifyContent: 'center !important',
                },
                '& .MuiTablePagination-root:last-child': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .MuiTablePagination-root .MuiTablePagination-selectLabel': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .MuiTablePagination-displayedRows': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .MuiDataGrid-virtualScroller': {
                    marginTop: '4vh !important',
                },
                '& .familyStyle': {
                    backgroundColor: LIGHT_BLUE,
                },
                '& .subFamilyStyle': {
                    mt: 2
                },
            }}
        >
            <ThemeProvider theme={theme}>
                <DataGrid
                    onCellClick={(row) => {
                        if (hasDrillDown) {
                            if (
                                row.id !== 'TOTAUX GENERAUX' &&
                                row.id !== 'TOTAUX TOP PRODUITS'
                            ) {
                                setOpen(true)
                                checkColumnAndPageSelected(type, row.field)
                                setFieldSelected(row)
                            }
                        }
                    }}
                    hideFooterSelectedRowCount={true}
                    rows={rows}
                    columns={columns}
                    getRowClassName={(params) =>
                        params.row.type === "family" ? 'familyStyle' : params.row.type === "subFamily" && !params.row.isFirstSubFamily ? "subFamilyStyle" : ""
                    }
                    getRowId={getRowId}
                    rowHeight={height * 0.035}
                    hideFooter={hideFooter}
                    getCellClassName={(
                        params: GridCellParams<any, any, number>
                    ) => {
                        if (params.id === 'TOTAUX GENERAUX') {
                            return 'PromoPageTotauxGeneraux'
                        }
                        if (params.id === 'TOTAUX TOP PRODUITS') {
                            return 'PromoPagetotauxTopProducts'
                        }
                    }}
                    sx={{
                        ...hasDrillDown ? { cursor: "pointer" } : { cursor: "auto" },
                        height: '100%',
                        '& .MuiDataGrid-main div + div': {
                            height: '100% !important',
                        },
                        '& .MuiDataGrid-row:hover': {
                            ...hasDrillDown ? { cursor: "pointer" } : { cursor: "auto" },
                        },
                    }}
                />
            </ThemeProvider>
        </Box>
    )
}


export const DataTableComponentNavigable = ({
    rows,
    columns,
    getRowId,
    hideFooter = false,
    navigate
}: DataTableComponentProps) => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                '& .MuiDataGrid-columnHeaders': {
                    height: '4vh',
                    maxHeight: 'unset !important',
                    minHeight: 'unset !important',
                },
                '& .MuiDataGrid-columnHeader': {
                    padding: '0 1vw !important',
                    height: '100% !important',
                },
                '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator:last-child':
                {
                    display: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    justifyContent: 'center !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer:first-of-type': {
                    justifyContent: 'center !important',
                },
                '& div.MuiDataGrid-columnHeader:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1)':
                {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft:first-of-type': {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft': {
                    justifyContent: 'center !important',
                },
                '& .MuiTablePagination-root:last-child': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .MuiTablePagination-root .MuiTablePagination-selectLabel': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .MuiTablePagination-displayedRows': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .MuiDataGrid-virtualScroller': {
                    marginTop: '4vh !important',
                },
            }}
        >
            <ThemeProvider theme={theme}>
                <DataGrid
                    onCellClick={(row) => {
                        navigate(row)
                    }}
                    hideFooterSelectedRowCount={true}
                    rows={rows}
                    columns={columns}
                    getRowId={getRowId}
                    rowHeight={height * 0.04}
                    hideFooter={hideFooter}
                    sx={{
                        cursor: 'pointer',
                        height: '100%',
                        '& .MuiDataGrid-main div + div': {
                            height: '100% !important',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                            "#MoreIcon": { color: `${MEDIUM_GREY} !important` }
                        },
                    }}
                />
            </ThemeProvider>
        </Box>
    )
}
