import React from 'react';
import ChartBlock from '../../../../../components/features/ChartBlock';
import DataGridDashboard from '../../../../../components/features/foodService/dashboard/DataGridDashboard';
import { GridColDef } from '@mui/x-data-grid';
import { DrillDownType } from '../../../../../components/features/drillDowns/ProductsDrillDownContent';
import { BLACK } from '../../../../../components/ui/Colors';
import { Grid } from '@mui/material';
import { PrimaryText } from '../../../../../components/ui/Text';
import { EnvName } from '../../../../../redux/user/userTypes';

const CercleVertNoWarehousesData = () => (
    <Grid alignItems="center" justifyContent="center" direction="column" container p="1vw" textAlign="center">
        <PrimaryText color={BLACK} mt="1vh">
            Ce tableau n'est pas disponible avec la maille partagée dans les données Cercle vert
        </PrimaryText>
    </Grid>
);

const NoDataPlaceholder = () => {
    return (
        <Grid alignItems="center" justifyContent="center" direction="column" container height="100%" textAlign="center">
            <PrimaryText color={BLACK} mt="1vh">
                Aucune donnée disponible
            </PrimaryText>
        </Grid>
    )
}



const DashboardDataGrid = ({
    columns,
    drilldown,
    data,
    title,
    link,
    drilldownType,
    envName
}: {
    columns: GridColDef[];
    drilldown: boolean;
    data: any;
    title: string;
    link: string;
    drilldownType?: DrillDownType;
    envName?: EnvName;
}) => {
    const child = envName === EnvName.CERCLEVERT ? <CercleVertNoWarehousesData /> : data.length > 0 ? (
        <DataGridDashboard
            columns={columns}
            drilldown={drilldown}
            data={data}
            drilldownType={drilldownType}
        />
    ) : (
        <NoDataPlaceholder />
    );

    return title ? (
        <ChartBlock title={title} link={link}>
            {child}
        </ChartBlock>
    ) : (
        <Grid>{child}</Grid>
    );
};

export default DashboardDataGrid;
