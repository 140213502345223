import React, { useSyncExternalStore } from 'react'
import {
    Box,
    Grid,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    Tooltip,
} from '@mui/material'
import { DataGrid, GridColDef, GridValidRowModel, frFR } from '@mui/x-data-grid'
import { TabPanelProps, getColumnsInformationTab } from './setupDrillDown'
import { DrillDownType } from './ProductsDrillDownContent'
import placeholder_picture from '../../../assets//placeholder_picture.png'
import { BLACK, LAVENDER, LIGHT_BLUE } from '../../ui/Colors'
import { PrimaryText, SecondaryText } from '../../ui/Text'
import NumberWithSpace from '../../ui/NumberWithSpace'
import Tendency from '../../ui/Tendency'
import { Units } from '../../../redux/global/globalTypes'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../helpers/ExternalStore'
import { roundIfNotZero } from '../../../helpers/roundIfNotZero'

const localeText = frFR.components.MuiDataGrid.defaultProps.localeText

delete localeText.noRowsLabel

interface CommonTabsProps {
    menus: string[]
    children?: any
    type?: DrillDownType
    row: any
    value: number
    handleChange: (event: React.SyntheticEvent, newValue: number) => void
}

export const DrillDownMenu = ({
    value,
    menus,
    handleChange,
}: {
    value: number
    menus: string[]
    handleChange: (event: React.SyntheticEvent, newValue: number) => void
}) => {
    return (
        <Grid>
            <Tabs
                sx={{
                    width: '100%',
                    mt: '4vh',
                    pl: '1vw',

                    '& .Mui-selected': {
                        background: LIGHT_BLUE,
                        borderBottom: `4px solid ${LAVENDER} !important`,
                    },
                }}
                value={value}
                onChange={handleChange}
            >
                {menus.map((menu, index) => (
                    <Tab
                        style={{
                            color: BLACK,
                            width: '13vw',
                        }}
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                        label={menu}
                        disableRipple
                        key={index}
                    />
                ))}
            </Tabs>
        </Grid>
    )
}

export const InformationsTable = ({
    rows,
}: {
    rows: readonly GridValidRowModel[]
}) => {
    return (
        <Grid>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    height: '44vh',
                    width: '44vw',
                    pr: '2vh',
                    pl: '5vh',
                }}
            >
                <Grid item width="100%"></Grid>
                <Grid item width="100%">
                    <Table>
                        <TableBody>
                            {rows.map((el) => {
                                return (
                                    <TableRow
                                        sx={{
                                            border: 1,
                                        }}
                                        key={el.id}
                                    >
                                        <TableCell
                                            sx={{
                                                border: 1,
                                                borderColor: '#E0E0E0',
                                                width: '50%',
                                            }}
                                        >
                                            <PrimaryText color={BLACK}>
                                                {el.columnName}
                                            </PrimaryText>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                border: 1,
                                                borderColor: '#E0E0E0',
                                            }}
                                        >
                                            <Grid
                                                container
                                                wrap="nowrap"
                                                justifyContent="space-between"
                                            >
                                                <SecondaryText color={BLACK}>
                                                    <NumberWithSpace
                                                        number={
                                                            el.information ===
                                                                '-'
                                                                ? '-'
                                                                : roundIfNotZero(
                                                                    el.information
                                                                )
                                                        }
                                                    />
                                                </SecondaryText>
                                                {el.difference != undefined && (
                                                    <Tendency
                                                        difference={
                                                            el.difference
                                                        }
                                                        unit={Units.none}
                                                    />
                                                )}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const InformationsTab = ({
    row,
    value,
    children,
    type,
}: {
    type: DrillDownType
    row: any
    value: number
    children: any
}) => {
    const rows = getColumnsInformationTab(type, row)

    return (
        <Grid>
            <CustomTabPanel value={value} index={0}>
                <InformationsTable rows={rows} />
                <Grid item>
                    <Tooltip title="[Fonctionnalité à venir] Un peu de patience... Bientôt vous pourrez ajouter vos propres visuels !">
                        <img
                            src={placeholder_picture}
                            alt="Image"
                            style={{
                                width: '20vw',
                            }}
                        />
                    </Tooltip>
                </Grid>
            </CustomTabPanel>
            {children}
        </Grid>
    )
}

export const CommonTabs = ({
    menus,
    row,
    value,
    children,
    handleChange,
    type,
}: CommonTabsProps) => {
    return (
        <Grid
            height="66vh"
            sx={{
                '& .MuiTabs-indicator': {
                    display: 'none',
                },
                '& .MuiTab-root.Mui-selected': {
                    color: '#666666',
                },
                '& .MuiTab-root': {
                    fontSize: { xs: '16px', md: '1.6vmin' },
                    padding: '1vw 2vh',
                    maxWidth: 'unset',
                },
            }}
        >
            <DrillDownMenu
                menus={menus}
                handleChange={handleChange}
                value={value}
            />
            <InformationsTab
                type={type}
                value={value}
                row={row}
                children={children}
            />
        </Grid>
    )
}

export const CustomTabPanel = ({ children, value, index }: TabPanelProps) => {
    return (
        <Grid>
            {value === index && (
                <Box
                    sx={{ p: '1vh', justifyContent: 'center', display: 'flex' }}
                >
                    {children}
                </Box>
            )}
        </Grid>
    )
}

export const messageToDisplay = ({
    type,
    value = 0,
    oldValue = 0,
}: {
    type: DrillDownType
    value?: number
    oldValue?: number
}) => {
    switch (type) {
        case DrillDownType.CLIENTS:
            let textToDisplay = ''
            if (value !== 0) {
                textToDisplay = `Vous avez vendu à ${value} sous-sous-types de client(s) sur la période sélectionnée et à ${oldValue} sous-sous-types d'utilisateurs finaux sur A-1.`
            }
            return textToDisplay
        case DrillDownType.WAREHOUSES:
            return `Vous avez vendu dans ${value} dépôt(s) sur la période sélectionnée et à ${oldValue} dépôt(s) sur A-1.`
        case DrillDownType.PRODUCTS:
            return `Vous avez vendu ${value} références(s) sur la période sélectionnée et ${oldValue} références(s) sur A-1.`
        case DrillDownType.STORES:
            return `Vous avez vendu dans ${value} magasin(s) sur la période sélectionnée et à ${oldValue} magasin(s) sur A-1.`
        case DrillDownType.RETAILPRODUCTS:
            return `Vous avez vendu ${value} références(s) sur la période sélectionnée et ${oldValue} références(s) sur A-1.`
    }
}

export const DataTableDrillDown = ({
    rows,
    columns,
    isFirstVisit,
    message,
    gridHeight = '44vh',
    hideFooter = false,
}: {
    rows: any[]
    columns: GridColDef[]
    isFirstVisit?: boolean
    message: string
    gridHeight?: string
    hideFooter?: boolean
}) => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    const noRowsMessage = {
        noRowsLabel: isFirstVisit ? '' : 'Pas de résultats',
    }

    return (
        <Grid
            sx={{
                height: gridHeight,
                pt: '2vh',
                pb: '2vh',
                pr: '2vh',
                pl: '2vh',
                width: '100%',
                '& .MuiDataGrid-row:hover #tendency': {
                    visibility: 'visible',
                },
                '& .MuiDataGrid-columnHeaders': {
                    height: '4vh',
                    maxHeight: 'unset !important',
                    minHeight: 'unset !important',
                },
                '& .MuiDataGrid-columnHeader': {
                    padding: '0 1vw',
                    height: '4vh',
                },
                '& .MuiDataGrid-virtualScroller': {
                    marginTop: '4vh !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    justifyContent: 'center !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer:first-of-type': {
                    justifyContent: 'center !important',
                },
                '& div.MuiDataGrid-columnHeader:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1)':
                {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft:first-of-type': {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft': {
                    justifyContent: 'center !important',
                },
                '& .MuiTablePagination-root:last-child': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .MuiTablePagination-root .MuiTablePagination-selectLabel': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .MuiTablePagination-displayedRows': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .familyStyle': {
                    backgroundColor: LIGHT_BLUE,
                },
                '& .subFamilyStyle': {
                    mt: 2
                },
            }}
        >
            <PrimaryText color={BLACK} pb="2vh">
                {message}
            </PrimaryText>
            <DataGrid
                hideFooterSelectedRowCount
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                    params.row.type === "family" ? 'familyStyle' : params.row.type === "subFamily" && !params.row.isFirstSubFamily ? "subFamilyStyle" : ""
                }
                localeText={{
                    ...noRowsMessage,
                    ...localeText,
                }}
                rowHeight={height * 0.035}
                hideFooter={hideFooter}
            />
            <SecondaryText style={{ whiteSpace: 'pre-wrap' }} mt="1vh">
                * Pour vous apporter une lecture optimale de vos données, les
                volumes sont arrondis sauf si compris entre -1 et 1
            </SecondaryText>
        </Grid>
    )
}
