import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import { useAppSelector } from '../../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import { PassionFroidColumnsWarehousesPage } from '../utils'
import { PagesName } from '../../../../components/features/navigation/types/pages'
import FsWarehousesPage from '../Pages/FsWarehousesPage'
import { WarehousesLayout } from '../layout/WarehousesLayout'

export const WarehousesPagePassionFroid = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage title="Dépôts" attribute={0} period>
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
                page={PagesName.WAREHOUSES}
            />
            <WarehousesLayout>
                <FsWarehousesPage
                    columns={PassionFroidColumnsWarehousesPage()}
                    drillDownType={DrillDownType.WAREHOUSESPF}
                />
            </WarehousesLayout>
        </PrivatePage>
    )
}
