import React, { useState } from 'react'
import PrivatePage from '../../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../../components/features/filters/Filters'
import { useAppSelector } from '../../../../../redux/hooks'
import { Grid } from '@mui/material'
import { WarehousesLayout } from '../../layout/WarehousesLayout'
import { PagesName } from '../../../../../components/features/navigation/types/pages'
import { DataTableComponent } from '../../../DataTableComponent'
import { DrillDownType } from '../../../../../components/features/drillDowns/ProductsDrillDownContent'
import { GridCellParams } from '@mui/x-data-grid'
import { GBHWarehousesColumns, GBHWarehousesHeadTabColumns } from '../../utils'
import DrillDown from '../../../../../components/features/drillDowns/DrillDown'
import { WarehousesTwoList } from '../../../../../redux/fsProducts/fsProductsTypes'
import ChartBlock from '../../../../../components/features/ChartBlock'

export const WarehousesPageGBH = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const warehouses = useAppSelector((state) => state.fsProducts.warehouses as WarehousesTwoList)

    const [isDrilldownWarehousesOpen, setDrilldownWarehousesOpen] = useState(false)
    const [fieldWarehousesSelected, setFieldWarehousesSelected] = useState<GridCellParams<any>>()
    const [warehousesValue, setWarehousesValue] = useState(0)


    const handleCloseWarehouses = () => {
        setDrilldownWarehousesOpen(false)
    }

    return (
        <PrivatePage title="Points de vente" attribute={0} period>
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
                page={PagesName.WAREHOUSES}
            />
            <WarehousesLayout >
                <Grid
                    container
                    direction="column"
                    sx={{
                        height: '90vh',
                        '& .MuiDataGrid-row:hover #tendency': {
                            visibility: 'visible',
                        },
                    }}
                    p="1vw"
                    flexWrap="nowrap"
                >
                    <ChartBlock
                        title={`Performances par points de vente`}
                    >
                        {warehouses && <Grid height={'100%'}>
                            <Grid height="20vh" mb="4vh">
                                <DataTableComponent
                                    rows={warehouses.regionsList}
                                    columns={GBHWarehousesHeadTabColumns()}
                                    getRowId={(row) => row.region}
                                    hasDrillDown={false}
                                />
                            </Grid>
                            <Grid height="45vh">
                                <DrillDown
                                    type={DrillDownType.WAREHOUSESGBH}
                                    open={isDrilldownWarehousesOpen}
                                    title={fieldWarehousesSelected && `${fieldWarehousesSelected.row.warehouse}`}
                                    value={warehousesValue}
                                    setValue={setWarehousesValue}
                                    row={fieldWarehousesSelected}
                                    handleClose={handleCloseWarehouses}
                                />
                                <DataTableComponent
                                    type={DrillDownType.WAREHOUSESGBH}
                                    setOpen={setDrilldownWarehousesOpen}
                                    setFieldSelected={setFieldWarehousesSelected}
                                    setValue={setWarehousesValue}
                                    rows={warehouses.warehousesList}
                                    columns={GBHWarehousesColumns()}
                                    getRowId={(row) => row.warehouse}
                                />
                            </Grid>
                        </Grid>}
                    </ChartBlock>
                </Grid>
            </WarehousesLayout>
        </PrivatePage >
    )
}
