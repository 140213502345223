import React, { useEffect, useState, useSyncExternalStore } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useAppSelector } from '../../../../../redux/hooks'
import { monthsAsObject } from '../../../../../helpers/months'
import { sortTwoArrSameWay } from '../../../../../helpers/sort'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../../helpers/ExternalStore'
import { Histogram } from '../../../../../components/features/foodService/dashboard/histogram/Histogram'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { dashboardLabels } from '../../../../../components/features/foodService/dashboard/histogram/HistogramLabels'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const HistogramFS = ({ title }: { title: string }) => {
    const chartRef = React.useRef()
    const [isLabels, setIsLabels] = useState<boolean>(false)
    const { name } = useAppSelector((state) => state.global.env)
    const { results } = useAppSelector((state) => state.global.fsDashboardData)
    const { unit } = useAppSelector((state) => state.global)
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    useEffect(() => {
        setIsLabels(true)
    }, [chartRef])

    const sortedOld = sortTwoArrSameWay(
        results.old.labels,
        results.old.data,
        true
    )
    const sortedCurrent = sortTwoArrSameWay(
        results.current.labels,
        results.current.data,
        true
    )

    const dataLastYearIsNull =
        sortedOld.second.length === 1 && sortedOld.second[0] === 0

    const data = {
        labels: sortedOld.base,
        datasets: dataLastYearIsNull
            ? [
                {
                    data: sortedCurrent.second,
                    backgroundColor: dashboardLabels[1].backgroundColor,
                    borderColor: dashboardLabels[1].backgroundColor,
                },
            ]
            : [
                {
                    data: sortedOld.second,
                    backgroundColor: dashboardLabels[0].backgroundColor,
                    borderColor: dashboardLabels[0].backgroundColor,
                },
                {
                    data: sortedCurrent.second,
                    backgroundColor: dashboardLabels[1].backgroundColor,
                    borderColor: dashboardLabels[1].backgroundColor,
                },
            ],
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                    callback: (value) => {
                        return monthsAsObject[sortedOld.base[value].slice(5, 7)]
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: (model: any) => {
                        const month = monthsAsObject[model[0].label.slice(5, 7)]
                        const year =
                            model[0].datasetIndex === 0
                                ? model[0].label.slice(0, 4)
                                : parseInt(model[0].label.slice(0, 4)) + 1

                        return `${month} ${year}`
                    },
                    label: (model: any) => {
                        const value = Math.round(model.raw)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        return model.datasetIndex === 1
                            ? `Volume A : ${value} ${unit}`
                            : `Volume A-1 : ${value} ${unit}`
                    },
                    afterLabel: (model: any) => {
                        const value = Math.round(
                            sortedOld.second[model.dataIndex]
                        )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        return model.datasetIndex === 1
                            ? `Volume A-1 : ${value} ${unit}`
                            : ''
                    },
                    footer: (model: any) => {
                        const value = model[0].raw
                        const valueLastYear =
                            sortedOld.second[model[0].dataIndex]
                        const difference = value - valueLastYear

                        if (valueLastYear === 0) {
                            return 'Évolution : -'
                        }

                        return model[0].datasetIndex === 1
                            ? difference === 0
                                ? ''
                                : difference > 0
                                    ? `Évolution : ↑ ${Math.round(
                                        (difference * 100) / valueLastYear
                                    )} %`
                                    : `Évolution : ↓ ${Math.round(
                                        (difference * 100) / valueLastYear
                                    )} %`
                            : ''
                    },
                },
            },
        },
    }

    return (
        <ChartBlock
            title={title}
            link={`/food-service/${name}/produits`}
        >
            <Histogram labels={dashboardLabels} data={data} options={options} height="14vh" />
        </ChartBlock>
    )
}
