import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import { Grid } from '@mui/material'
import { FsCrossTable } from './FoodService/FsCrossTable'
import ChartBlock from '../../../../components/features/ChartBlock'
import { Units } from '../../../../redux/global/globalTypes'
import FsKpis from '../Pages/Dashboard/FsKpis'
import FsCrossLineChart from './FoodService/FsCrossLineChart'
import FsCrossDashboardLayout from './layout/FsCrossDashboardLayout'
import { useAppSelector } from '../../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import FsCrossSegmentation from './FoodService/FsCrossSegmentation'

export const KPIsFsCrossConfig = ({
    KPIs,
}: {
    KPIs: any
}): any => [
        {
            id: 1,
            name: 'Quantité totale facturée',
            units: Units.kg,
            description: `Quantité totale facturée sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.differenceVolume,
            evolution: KPIs.evolutionVolume,
        },
        {
            id: 2,
            name: `Nombre de références`,
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: KPIs.products,
            difference: KPIs.differenceProducts,
            evolution: KPIs.evolutionProducts,
        },
        {
            id: 3,
            name: 'VMM',
            units: Units.kg,
            description: `Ventes moyennes mensuelles : indication en volume de la rotation de vos produits et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.VMM),
            difference: KPIs.differenceVMM,
            evolution: KPIs.evolutionVMM
        },
    ]

const FsCrossDashboard = () => {
    const { activeFsCrossFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage title="Tableau de bord" period>
            <Filters
                filtersType={FiltersType.FSCROSS}
                filters={activeFsCrossFilters}
            />
            <FsCrossDashboardLayout>
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    height="90vh"
                    p="1vw"
                >
                    <FsKpis KPIsConfig={KPIsFsCrossConfig} />
                    <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        justifyContent="flex-start"
                        pt="1vw"
                        sx={{ height: '80vh' }}
                    >
                        <Grid item sx={{ height: '40%' }}>
                            <FsCrossLineChart />
                        </Grid>
                        <Grid
                            container
                            wrap="nowrap"
                            pt="1vw"
                            sx={{ height: '60%' }}
                        >
                            <Grid
                                item
                                flex={1.5}
                                sx={{ height: '100%', width: '14vw' }}

                            >
                                <FsCrossSegmentation title={`Segmentation utilisateurs par volume (${Units.kg})`} />
                            </Grid>
                            <Grid
                                container
                                flexWrap="nowrap"
                                direction="column"
                                flex={2}
                                pl="1vw"
                                sx={{ height: '100%' }}
                            >
                                <ChartBlock title="Répartition des ventes par distributeur">
                                    <Grid container wrap="nowrap" height="100%">
                                        <FsCrossTable />
                                    </Grid>
                                </ChartBlock>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </FsCrossDashboardLayout>
        </PrivatePage>
    )
}

export default FsCrossDashboard
