import React from 'react'
import { Grid, Tab, Tabs } from '@mui/material'
import { BLACK, LAVENDER, LIGHT_BLUE } from '../../../../components/ui/Colors'

export const MenuProfil = ({ data }: { data: string[] }) => {
    const [value, setValue] = React.useState(0)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Grid
            container
            width="14vw"
            mr="3vw"
            ml="3vw"
            mt="15vh"
            position="fixed"
            sx={{
                '& .MuiTabs-indicator': {
                    display: 'none',
                },
                '& .MuiTab-root.Mui-selected': {
                    color: '#666666',
                },
                '& .MuiTab-root': {
                    fontSize: { xs: '16px', md: '1.6vmin' },
                    padding: '1vw 2vh',
                    maxWidth: 'unset',
                },
            }}
        >
            <Tabs
                id="tabs"
                orientation="vertical"
                value={value}
                onChange={handleChange}
                sx={{
                    borderRight: 1,
                    borderColor: 'divider',

                    '& .active ': {
                        background: LIGHT_BLUE,
                        borderRight: `2px solid ${LAVENDER} !important`,
                    },
                }}
            >
                {data.map((el, index) => (
                    <Tab
                        style={{
                            color: BLACK,
                        }}
                        label={el}
                        href={`#${index}`}
                        key={index}
                    ></Tab>
                ))}
            </Tabs>
        </Grid>
    )
}
