import { DialogContent, Grid } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { date, object, ref } from 'yup'
import FilterSelect from '../filters/FilterSelect'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { StepperData } from '../../../redux/stepper/stepperTypes'
import { PrimaryText } from '../../ui/Text'
import { DialogFooter } from '../Dialog/BasicFooterDialog'
import { getPlaceholderDates } from '../../../helpers/months'
import { BLACK } from '../../ui/Colors'
import { ExportComponentProps, StepExportConfig } from './ExportSetup'
import { EnvType } from '../../../redux/user/userTypes'
import { fetchFiltersExport } from '../../../redux/export/exportsSlice'
import {
    ActiveFsExportFilters,
    ActiveRetailExportFilters,
} from '../../../redux/export/exportsTypes'
import Loading from '../screens/LoadingScreen'
// ICONS
import InventoryIcon from '@mui/icons-material/Inventory'
import RoomIcon from '@mui/icons-material/Room'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import { FiltersItem, MenuType } from '../filters/utils'

interface FormValues {
    startDate: Date
    endDate: Date
    products: string[]
    regions: string[]
    clients?: string[]
}

interface FormProps {
    initialValues: ActiveFsExportFilters | ActiveRetailExportFilters
    filtersToDisplay: FiltersItem[]
    config: StepExportConfig
    step: number
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
    environment: EnvType
    isLoading: boolean
}

const PureMultiExportFilterStepForm = ({
    setFieldValue,
    submitForm,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    const [modified, setModified] = useState(false)
    const [printedPeriod, setPrintedPeriod] = useState<string | null>(null)

    useEffect(() => {
        if (props.values.startDate && props.values.endDate) {
            const period = getPlaceholderDates(
                props.values.startDate,
                props.values.endDate
            )
            setPrintedPeriod(period)
        }
    }, [props.values])

    return (
        <Form style={{ width: '100%' }}>
            <DialogContent sx={{ height: '32vh' }}>
                {props.isLoading ? (
                    <Grid container alignItems="center" height="100%">
                        <Loading text={false} height="100%" />
                    </Grid>
                ) : (
                    <Grid>
                        <PrimaryText color={BLACK}>
                            Sélectionnez les champs que vous souhaitez filtrer :
                        </PrimaryText>
                        <Grid
                            container
                            style={{
                                marginTop: '3vh',
                                padding: 4,
                            }}
                        >
                            <PrimaryText width="30%">
                                Mois et année :{' '}
                            </PrimaryText>
                            <Grid sx={{ flex: 1, width: '100%' }}>
                                {/* TODO : <FilterCalendar
                                    isModified={modified}
                                      placeholder={printedPeriod}
                                    setFieldValue={setFieldValue}
                                    setModified={setModified}
                                    start={props.values.startDate}
                                    end={props.values.endDate}
                                    error={props.errors.endDate as string}
                                    env={props.environment}
                                /> */}
                            </Grid>
                        </Grid>
                        {props.filtersToDisplay.map((filter, index) => (
                            <Grid
                                container
                                style={{
                                    padding: 4,
                                }}
                                key={index}
                            >
                                <PrimaryText width="30%">
                                    {filter.placeholder} :{' '}
                                </PrimaryText>
                                <FilterSelect
                                    name={filter.name}
                                    icon={filter.icon}
                                    placeholder={filter.placeholder}
                                    type={filter.type}
                                    values={props.values[filter.name]}
                                    setFieldValue={setFieldValue}
                                    key={filter.id}
                                    env={props.environment}
                                    export={true}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </DialogContent>
            <DialogFooter
                leftButtonFunc={(e) => {
                    props.config.handlePrevious()
                }}
                leftButtonText={'PRECEDENT'}
                rightButtonFunc={(e) => {
                    e.stopPropagation()

                    submitForm()
                }}
                rightButtonText={'SUIVANT'}
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        startDate: new Date(props.initialValues.startDate),
        endDate: new Date(props.initialValues.endDate),
        products: props.initialValues.products,
        regions: props.initialValues.regions,
        clients: [],
    }),
    validationSchema: () => {
        return object().shape({
            startDate: date()
                .min(new Date('2020-01-01'))
                .required('Choississez une date de début'),
            endDate: date()
                .min(
                    ref('startDate'),
                    'Sélectionnez une date plus ancienne que celle de début'
                )
                .max(new Date())
                .required('Choississez une date de fin'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureMultiExportFilterStepForm)

const MultiExportFiltersStep = ({ step, config }: ExportComponentProps) => {
    const { environment, distributors } = config.getData() as unknown as any
    const [isLoading, setLoading] = useState<boolean>(true)
    const dispatch = useAppDispatch()

    const _fetchFiltersExport = useCallback(async () => {
        await dispatch(fetchFiltersExport(distributors, environment))
    }, [dispatch])

    useEffect(() => {
        _fetchFiltersExport().then(() => setLoading(false))
    }, [])

    const filters =
        environment === EnvType.FOOD_SERVICE
            ? useAppSelector((state) => state.exports.activeFsExportFilters)
            : useAppSelector((state) => state.exports.activeRetailExportFilters)

    const handleSubmit = (values: FormValues) => {
        config.updateData(values as unknown as StepperData)
        config.handleNext()
    }
    const filtersToDisplay =
        environment === EnvType.FOOD_SERVICE
            ? filtersExportMultiFS
            : filtersExportMultiRetail

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            initialValues={filters}
            filtersToDisplay={filtersToDisplay}
            environment={environment}
            isLoading={isLoading}
        />
    )
}

export default MultiExportFiltersStep

const filtersExportMultiFS: FiltersItem[] = [
    {
        id: 0,
        name: 'products',
        icon: <InventoryIcon />,
        placeholder: 'Références',
        type: MenuType.PRODUCTS,
    },
    {
        id: 2,
        name: 'regions',
        icon: <RoomIcon />,
        placeholder: 'Régions',
        type: MenuType.REGION,
    },
    {
        id: 3,
        name: 'clients',
        icon: <EmojiPeopleIcon />,
        placeholder: "Types d'utilisateurs finaux",
        type: MenuType.CLIENTS,
    },
]

const filtersExportMultiRetail: FiltersItem[] = [
    {
        id: 0,
        name: 'products',
        icon: <InventoryIcon />,
        placeholder: 'Références',
        type: MenuType.PRODUCTS,
    },
    {
        id: 2,
        name: 'regions',
        icon: <RoomIcon />,
        placeholder: 'Régions',
        type: MenuType.REGION,
    },
]
