import React from 'react'
import PrivatePage from '../../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../../components/features/filters/Filters'
import { PagesName } from '../../../../../components/features/navigation/types/pages'
import { useAppSelector } from '../../../../../redux/hooks'
import { Grid } from '@mui/material'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { DetailTab } from '../../../../../components/ui/TabsForDataTableComponent'
import { SuppliersLayout } from '../../layout/SuppliersLayout'
import { SuppliersPerBrand } from './SuppliersPerBrand'
import { SuppliersPerSegment } from './SuppliersPerSegment'
import { SuppliersPerRegion } from './SuppliersPerRegion'
import { Histogram } from '../../../../../components/features/foodService/dashboard/histogram/Histogram'

export const suppliersLabels = [
    {
        id: 0,
        title: 'PDM A-1',
        backgroundColor: '#ADB2D3',
    },
    {
        id: 1,
        title: 'PDM A',
        backgroundColor: '#3A4D87',
    }
]


const dataGridConfig = () => {
    return [
        {
            id: "1",
            label: "Par territoire",
            children: <SuppliersPerRegion />
        },
        {

            id: "2",
            label: "Par segment",
            children: <SuppliersPerSegment />
        },
        {
            id: "3",
            label: "Par marque",
            children: <SuppliersPerBrand />
        },
    ]
}

export const SuppliersPageGBH = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { suppliers } = useAppSelector((state) => state.fsProducts)
    const config = dataGridConfig()

    const data = suppliers && suppliers.suppliersHistogram ? {
        labels: suppliers.suppliersHistogram.current.labels,
        datasets:
            [
                {
                    data: suppliers.suppliersHistogram.old.data,
                    backgroundColor: suppliersLabels[0].backgroundColor,
                    borderColor: suppliersLabels[0].backgroundColor,
                },
                {
                    data: suppliers.suppliersHistogram.current.data,
                    backgroundColor: suppliersLabels[1].backgroundColor,
                    borderColor: suppliersLabels[1].backgroundColor,
                },
            ],
    } : null

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    }

    return (
        <PrivatePage title="Fournisseurs" period>
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
                page={PagesName.CLIENTS}
            />
            <SuppliersLayout>
                <Grid>
                    <Grid
                        container
                        direction="column"
                        sx={{
                            height: '60vh',
                            '& .MuiDataGrid-row:hover #tendency': {
                                visibility: 'visible',
                            },
                        }}
                        p="1vw"
                        flexWrap="nowrap"
                    >
                        <ChartBlock
                            title={`Parts de marché`}
                        >
                            <Grid width="100%">
                                <Histogram labels={suppliersLabels} data={data} options={options} height="45vh" />
                            </Grid>
                        </ChartBlock>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        sx={{
                            '& .MuiDataGrid-row:hover #tendency': {
                                visibility: 'visible',
                            },
                        }}
                        p="1vw"
                        flexWrap="nowrap"
                    >
                        <ChartBlock
                            title={`Performances par fournisseur`}
                        >
                            <DetailTab data={config}></DetailTab>
                        </ChartBlock>
                    </Grid>
                </Grid>
            </SuppliersLayout>
        </PrivatePage >
    )
}
