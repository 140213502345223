import React, { useState } from 'react'
import Label from './PieLabel'
import { Grid, Tooltip, Typography } from '@mui/material'
import { SquareColor } from '../../../../../../components/ui/ColorSquare'
import { BLACK } from '../../../../../../components/ui/Colors'
import { roundIfNotZero } from '../../../../../../helpers/roundIfNotZero'

type LegendState = Array<{ type: number; label: number }>

interface OwnProps {
    chart: any
    labels: string[]
    data: number[]
}

export const labelsConfig = [
    {
        backgroundColor: [
            '#3A4D87',
            '#D4F7A6',
            '#EF9BFD',
            '#F7EAA6',
            '#F7B0A6',
            '#FACE5D',
            '#A17D5B',
        ],
    },
]

const PieLabels = ({ chart, labels, data }: OwnProps) => {
    const total = data.reduce((acc, curr) => acc + curr, 0)
    const [legend, setLegend] = useState<LegendState>([])

    const showData = (dataset: number, label: number) => {
        setLegend(
            legend.filter((el) => {
                if (el.type === dataset) {
                    return el.label !== label
                }
                return true
            })
        )
        chart.show(dataset, label)
    }

    const hideData = (dataset: number, label: number) => {
        const labelsItems = legend.filter((elem) => elem.type === dataset)
        if (labelsItems.length < labels.length - 1) {
            setLegend((current) => [...current, { type: dataset, label }])
            chart.hide(dataset, label)
        }
    }

    const toggleData = (dataset: number, label: number) => {
        const el = legend.find(
            (pair) => pair.type === dataset && pair.label === label
        )

        if (el) {
            showData(dataset, label)
        } else {
            hideData(dataset, label)
        }
    }

    return (
        <Grid>
            <Grid
                alignItems={'center'}
                justifyContent="center"
                container
                mb="2vh"
            >
                <Grid mt="1vh" margin="auto">
                    {labels.map((label, labelIndex) => {
                        if (labelIndex > 4) return null
                        return (
                            <Label
                                label={
                                    label +
                                    ' : ' +
                                    roundIfNotZero(
                                        (data[labelIndex] / total) * 100
                                    ) +
                                    ' %'
                                }
                                color={
                                    labelsConfig[0].backgroundColor[labelIndex]
                                }
                                size={SquareColor.SM}
                                clickHandler={() => {
                                    toggleData(0, labelIndex)
                                }}
                                hide={
                                    legend.findIndex(
                                        (pair) => pair.label === labelIndex
                                    ) >= 0
                                }
                                key={labelIndex}
                            />
                        )
                    })}
                    {labels.length > 5 ? (
                        <Tooltip
                            title={
                                <Grid container direction="column">
                                    {labels
                                        .slice(5, labels.length)
                                        .map((label, labelIndex) => {
                                            return (
                                                <span>
                                                    {`${label} `}
                                                    {'('}
                                                    {(
                                                        (data[labelIndex + 5] /
                                                            total) *
                                                        100
                                                    ).toFixed(2)}
                                                    {labelIndex ===
                                                        labels.length - 5 - 1
                                                        ? ' %) '
                                                        : ' %), '}
                                                </span>
                                            )
                                        })}
                                </Grid>
                            }
                        >
                            <Typography
                                sx={{
                                    fontSize: '1.2vmin',
                                    color: BLACK,
                                    mt: '1.5vh',
                                    ml: '2vw',
                                    textDecoration: 'underline',
                                    fontFamily: "Cabin"
                                }}
                            >
                                {`Voir ${labels.length - 5} de +`}
                            </Typography>
                        </Tooltip>
                    ) : undefined}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PieLabels
