import { Grid } from '@mui/material'
import { type FormikBag, type FormikProps, withFormik, Form } from 'formik'
import React, { useState } from 'react'
import { object, string } from 'yup'
import Activated from '../../../../../../assets/inactivated_carrefour.svg'
import { PrimaryText } from '../../../../../../components/ui/Text'
import { DARK_GREY } from '../../../../../../components/ui/Colors'
import {
    EnhancedField,
    TextInput,
} from '../../../../../../components/ui/Inputs'
import { Inputs } from '../../../../../public/authentication/signUp/steps/SignUpAccount'
import { DialogFooter } from '../../../../../../components/features/Dialog/BasicFooterDialog'
import { ConfigProps } from '../AutoInactivated'

interface FormValues {
    email: string
    password: string
}

interface FormProps {
    onSubmit: (values: FormValues) => void
    isSubmit: boolean
    config: ConfigProps
}

const inputs: Inputs[] = [
    { type: 'email', name: 'email', label: 'Identifiant' },
    {
        type: 'password',
        name: 'password',
        label: 'Mot de passe',
    },
]

const PureCarrefourForm = (props: FormikProps<FormValues> & FormProps) => {
    return (
        <Form style={{ width: '70vw', height: '100%' }}>
            <Grid container wrap="nowrap" alignItems="center">
                <Grid textAlign="center" padding={5} flex={1}>
                    <Grid>
                        <img
                            alt="Besoin d'aide"
                            src={Activated}
                            style={{ width: '18em', height: '15em' }}
                        />
                    </Grid>
                    <PrimaryText>
                        <a
                            rel="noopener noreferrer"
                            target={'_blank'}
                            style={{ color: DARK_GREY, fontSize: '16px' }}
                            href="https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed"
                        >
                            Besoin d'aide ?
                        </a>
                    </PrimaryText>
                </Grid>
                <Grid flex={3}>
                    <Grid justifyContent="center" mt="5vh" flex={3}>
                        <PrimaryText>
                            Saisissez vos identifiants Data shopper ou Memory.
                        </PrimaryText>
                        <Grid>
                            <Grid>
                                {inputs.map((input, index) => (
                                    <EnhancedField
                                        type={input.type}
                                        name={input.name}
                                        label={input.label}
                                        autoFocus={index === 0}
                                        margin="normal"
                                        component={TextInput}
                                        key={index}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                        <PrimaryText>
                            Ce connecteur autorise KaryonFood à recevoir vos
                            données.
                        </PrimaryText>
                    </Grid>
                </Grid>
            </Grid>
            <DialogFooter
                rightButtonFunc={(e) => {
                    e.stopPropagation()
                    props.config.handleNext()
                }}
                rightButtonText="Connecter"
                leftButtonFunc={(e) => {
                    e.stopPropagation()
                    props.config.handleClose(e)
                }}
                leftButtonText="Fermer"
            />
        </Form>
    )
}

const ControlledCarrefourForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        email: '',
        password: '',
    }),
    validationSchema: () => {
        return object().shape({
            email: string().required('Le champ Identifiant est obligatoire'),
            password: string().required(
                'Le champ Mot de passe est obligatoire'
            ),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureCarrefourForm)

const CarrefourInactivatedAuthentification = ({
    config,
}: {
    config: ConfigProps
}) => {
    const [isSubmit, setIsSubmit] = useState<boolean>(false)

    const handleSubmit = async (values: FormValues) => {
        setIsSubmit(true)
    }

    return (
        <ControlledCarrefourForm
            config={config}
            onSubmit={handleSubmit}
            isSubmit={isSubmit}
        />
    )
}

export default CarrefourInactivatedAuthentification
