import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { ActiveFsFilters } from '../../../../redux/filters/filtersTypes'
import { fetchAssortmentPromo, fetchCostPromo, fetchImpactPromo, fetchPerformancePromo, fetchPromoKPIs, fetchWarehousesPromo } from '../../../../redux/promotions/promotionsSlice'

export const CampaignLayout = ({ children, filters }: { children: JSX.Element, filters: ActiveFsFilters }) => {
    const dispatch = useAppDispatch()
    const [isFirstLoad, setIsFirstLoad] = useState(true)

    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const isFirstUpdate = useRef(true)

    const _fetchPromo = useCallback(
        (filters: ActiveFsFilters) => {
            dispatch(fetchPromoKPIs(filters))
            dispatch(fetchCostPromo(filters))
            dispatch(fetchPerformancePromo(filters))
            dispatch(fetchImpactPromo(filters))
            dispatch(fetchAssortmentPromo(filters))
            dispatch(fetchWarehousesPromo(filters))
        },
        [dispatch]
    )
    useEffect(() => {
        if (!isFirstUpdate.current) {
            _fetchPromo(filters as ActiveFsFilters)
            setIsFirstLoad(false)
            isFirstUpdate.current = false


        }
    }, [filters])

    useEffect(() => {
        if (activeFsFilters.startDate) {
            _fetchPromo(activeFsFilters)
            setIsFirstLoad(false)
            isFirstUpdate.current = false
        }
    }, [activeFsFilters, _fetchPromo,])

    return <Grid>{children}</Grid>
}
