import { Grid, List, ListItem, ListItemIcon, useTheme } from '@mui/material'
import React, { useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { PrimaryText, SecondaryText } from '../../../../../components/ui/Text'
import CGU from '../../../../../assets/CGV - 2023.pdf'
import { BLACK, PRIMARY } from '../../../../../components/ui/Colors'
import CircleIcon from '@mui/icons-material/Circle'
interface InformationProps {
    label: string
    initialValue: string | number | string[]
    type: string
}

interface DistributorProps {
    label: string
    distributors: string[]
    other: string
    dataLabel: string
    data: string[]
}

export const GeneralCoonditions = () => {
    return (
        <PrimaryText color={BLACK}>
            Je déclare avoir lu, compris et accepté les
            <a
                href={CGU}
                style={{ marginLeft: '5px', color: PRIMARY }}
                target={'_blank'}
                rel="noreferrer noopener"
            >
                Conditions générales d'utilisation de KaryonFood
            </a>
        </PrimaryText>
    )
}

export const PasswordInput = ({ password }: { password: string }) => {
    const [show, setShow] = useState<boolean>(false)

    const theme = useTheme()
    const fontFamily = theme.typography.fontFamily

    return (
        <Grid container wrap="nowrap" alignItems="center">
            <input
                type={show ? 'text' : 'password'}
                value={password}
                disabled
                style={{
                    border: 'none',
                    width: '120px',
                    color: 'black',
                    fontSize: '1.6vmin',
                    fontFamily: fontFamily,
                    padding: 0,
                }}
            />
            <VisibilityIcon
                onClick={() => {
                    setShow(!show)
                }}
                sx={{ cursor: 'pointer' }}
            />
        </Grid>
    )
}

export const InformationGrid = ({
    label,
    initialValue,
    type,
}: InformationProps) => {
    if (type === 'text' || type === 'email') {
        return (
            <Grid container alignItems="center" mb="1vh">
                <Grid flex={1}>
                    <PrimaryText color={BLACK}>{label} :</PrimaryText>
                </Grid>
                <Grid flex={1}>
                    <PrimaryText color={BLACK}>{initialValue}</PrimaryText>
                </Grid>
            </Grid>
        )
    } else if (type === 'list') {
        const values = initialValue as string[]
        return (
            <Grid container direction="column">
                <PrimaryText color={BLACK}>{label} :</PrimaryText>
                <Grid>
                    <List sx={{ p: 0 }}>
                        {values.map((input, index) => (
                            <ListItem
                                sx={{
                                    p: 0,
                                    m: 0,
                                    alignItems: 'flex-start',
                                    pt: '0.5vh',
                                }}
                                key={index}
                            >
                                <ListItemIcon>
                                    <CircleIcon
                                        sx={{
                                            color: BLACK,
                                            height: '1vh',
                                            pt: '0.8vh',
                                        }}
                                    />
                                </ListItemIcon>
                                <PrimaryText color={BLACK}>{input}</PrimaryText>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container alignItems="center" mb="1vh">
                <PrimaryText color={BLACK} flex={1}>
                    Mot de passe :
                </PrimaryText>
                <Grid flex={1}>
                    <PasswordInput password={initialValue as string} />
                </Grid>
            </Grid>
        )
    }
}

export const Distributor = ({
    distributors,
    dataLabel,
    data,
    other,
    label,
}: DistributorProps) => {
    return (
        <Grid>
            <Grid container alignItems="center" mb="1vh">
                <PrimaryText color={BLACK} mr="0.5vw">
                    {label} :
                </PrimaryText>
                {distributors.length >= 1 ? (
                    <SecondaryText color={BLACK}>
                        {distributors.reduce((acc, l) => {
                            return `${acc + ' - ' + l}`
                        })}
                    </SecondaryText>
                ) : (
                    <SecondaryText color={BLACK}> Aucun </SecondaryText>
                )}
            </Grid>
            <Grid container alignItems="center" mb="1vh">
                <PrimaryText color={BLACK}>{dataLabel} : </PrimaryText>
                {data.length >= 1 ? (
                    <SecondaryText color={BLACK}>
                        {distributors.reduce((acc, l) => {
                            return `${acc + ' - ' + l}`
                        })}
                    </SecondaryText>
                ) : (
                    <SecondaryText color={BLACK} ml={1}>
                        Aucun
                    </SecondaryText>
                )}
            </Grid>
        </Grid>
    )
}
