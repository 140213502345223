import React, { SyntheticEvent } from 'react'
import { Accordion, AccordionSummary, Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
    List,
    ListLabelsCustomer,
    ListLabelsCustomerProps,
} from './ListLabelsCustomer'

import CheckboxLabels from '../../../../../components/features/foodService/dashboard/graph/MyCheckBoxUpdate'
import { LIGHT_MOCK } from '../../../../../components/ui/Colors'
import { Pages } from '../../../DataTableComponent'
import { SegmentationType } from '../../../../../redux/fsProducts/fsProductsTypes'
import { useAppSelector } from '../../../../../redux/hooks'
import { EnvName } from '../../../../../redux/user/userTypes'

interface Props extends ListLabelsCustomerProps {
    handleChange: (
        a: string
    ) => (event: SyntheticEvent<Element, Event>, expanded: boolean) => void
    countInLegend: number
    expanded: string | false
    panel: string
    isOneLevelOnly: boolean
    checked?: boolean
    toggleDataSets: (layer: number, e: React.MouseEvent) => void
}

const AccordionLabels = {
    [SegmentationType.TYPE]: 'Types',
    [SegmentationType.SUB_TYPE]: 'Sous-types',
    [SegmentationType.SUB_SUB_TYPE]: 'Sous-sous-types',
}

const legendIn3parts = (list: List) => {
    const longer = list.data.length

    if (longer === 1) {
        return [list]
    } else if (longer === 2) {
        return [
            {
                data: [list.data[0]],
                labels: [list.labels[0]],
                percentage: [list.percentage[0]],
            },
            {
                data: [list.data[1]],
                labels: [list.labels[1]],
                percentage: [list.percentage[1]],
            },
        ]
    }
    const tier = (list.data.length / 100) * 33
    const dividerFirstPart = Math.ceil(tier)
    const dividerSecondPart =
        tier % 1 > 0.5 ? Math.ceil(tier) : Math.floor(tier)
    // DIVIDE DATA
    const dataFirstPart = list.data.slice(0, dividerFirstPart)
    const dataSecondPart = list.data.slice(
        dividerFirstPart,
        dividerSecondPart + dividerFirstPart
    )
    const dataThirdPart = list.data.slice(-dividerSecondPart)
    // DIVIDE LABELS
    const labelsFirstPart = list.labels.slice(0, dividerFirstPart)
    const labelsSecondPart = list.labels.slice(
        dividerFirstPart,
        dividerSecondPart + dividerFirstPart
    )
    const labelsThirdPart = list.labels.slice(-dividerSecondPart)
    // DIVIDE Percentage
    const percentageFirstPart = list.percentage.slice(0, dividerFirstPart)
    const percentageSecondPart = list.percentage.slice(
        dividerFirstPart,
        dividerSecondPart + dividerFirstPart
    )
    const percentageThirdPart = list.percentage.slice(-dividerSecondPart)

    return [
        {
            data: dataFirstPart,
            labels: labelsFirstPart,
            percentage: percentageFirstPart,
        },
        {
            data: dataSecondPart,
            labels: labelsSecondPart,
            percentage: percentageSecondPart,
        },
        {
            data: dataThirdPart,
            labels: labelsThirdPart,
            percentage: percentageThirdPart,
        },
    ]
}

export const AccordionCustomerLegend = ({
    handleChange,
    list,
    hideItem,
    toggleData,
    countInLegend,
    expanded,
    panel,
    toggleNumber,
    type,
    isOneLevelOnly,
    checked,
    toggleDataSets,
}: Props) => {
    return (
        <Grid
            mb="1vw"
            sx={{
                '& .MuiAccordionSummary-root:hover': {
                    cursor: !isOneLevelOnly ? 'pointer' : 'auto',
                },
            }}
        >
            <Accordion
                expanded={expanded === `${panel}`}
                onChange={handleChange(`${panel}`)}
            >
                <AccordionSummary
                    aria-controls={`${panel}bh-content`}
                    id={`${panel}bh-header`}
                    expandIcon={
                        !isOneLevelOnly ? (
                            <ExpandMoreIcon sx={{ width: '2vw' }} />
                        ) : (
                            <></>
                        )
                    }
                    sx={{
                        '& div': { margin: 0 },
                        m: 0,
                        p: '0 1vw',
                        minHeight: 'unset',
                        height: '8vh',
                    }}
                >
                    <CheckboxLabels
                        checked={checked}
                        page={Pages.CLIENTS}
                        layer={toggleNumber}
                        toggleDataSets={toggleDataSets}
                        label={`${AccordionLabels[type]} (${list.labels.length - countInLegend
                            }/${list.labels.length})`}
                    />
                </AccordionSummary>
                <Grid
                    container
                    justifyContent="space-between"
                    sx={{
                        borderTop: `1px solid ${LIGHT_MOCK}`,
                        p: '2vh 0',
                    }}
                >
                    {legendIn3parts(list).map((part, index) => {
                        return (
                            <ListLabelsCustomer
                                list={part}
                                hideItem={hideItem}
                                toggleNumber={toggleNumber}
                                toggleData={toggleData}
                                type={type}
                                index={index}
                                key={index}
                            />
                        )
                    })}
                </Grid>
            </Accordion>
        </Grid>
    )
}
