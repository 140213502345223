import React from 'react'
import PrivatePage from '../../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../../components/features/filters/Filters'
import { useAppSelector } from '../../../../../redux/hooks'
import { ProductsLayout } from '../../layout/ProductsLayout'
import { DetailTab } from '../../../../../components/ui/TabsForDataTableComponent'
import { Grid } from '@mui/material'
import { ProductsPerBrand } from './ProductsPerBrand'
import { ProductsPerSegment } from './ProductsPerSegment'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { LineChartProduct } from '../../Pages/ProductsPage/LineChartProduct'
import { ProductsChartAndTwoList } from '../../../../../redux/fsProducts/fsProductsTypes'


const dataGridConfig = () => {
    return [{
        id: "1",
        label: "Par marque",
        children: <ProductsPerBrand />
    },
    {
        id: "2",
        label: "Par segment",
        children: <ProductsPerSegment />
    }

    ]
}

export const ProductsPageGBH = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const config = dataGridConfig()
    const products = useAppSelector((state) => state.fsProducts.products as unknown as ProductsChartAndTwoList)

    return (
        <PrivatePage title="Références" attribute={0} period>
            <Filters filtersType={FiltersType.FS} filters={activeFsFilters} />
            <ProductsLayout>
                <Grid
                    container
                    direction="column"
                    sx={{
                        height: '110vh',
                        '& .MuiDataGrid-row:hover #tendency': {
                            visibility: 'visible',
                        },
                    }}
                    p="1vw"
                    flexWrap="nowrap"
                >
                    <Grid item>
                        {products &&
                            <ChartBlock title={"Volume vendu par référence"}>
                                <LineChartProduct chart={products.chart} period={products.period} legendDepth={1} />
                            </ChartBlock>
                        }
                    </Grid>
                    <Grid height="47vh" item mt="1vw">
                        <ChartBlock
                            title={`Performances par références`}
                        >
                            <DetailTab data={config}></DetailTab>
                        </ChartBlock>
                    </Grid>
                </Grid>

            </ProductsLayout>
        </PrivatePage>
    )
}
