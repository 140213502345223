import React, { useState } from "react"
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid"
import { PrimaryText, SecondaryText } from "../../../../../../../components/ui/Text"
import { BLACK, WHITE } from "../../../../../../../components/ui/Colors"
import NumberWithSpace from "../../../../../../../components/ui/NumberWithSpace"
import { ArrowTendency } from "../../../../../../../components/ui/ArrowTendency"
import { Grid } from "@mui/material"
import { Units } from "../../../../../../../redux/global/globalTypes"
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ActionMenu } from "../../../../../../../components/ui/ActionMenu"
import { ModifyCampaignMenu } from "./ModifyCampaign"
import { UnityType } from "../../../../../../../redux/promotions/promotionsTypes"

export const columnsCampaignsTab = (unit: Units, unityType: UnityType): GridColDef[] => {
    const [open, setOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [fieldSelected, setFieldSelected] = useState<any>()
    const handleClick = (e: React.MouseEvent, value: any) => {
        e.stopPropagation()
        e.preventDefault()
        if (open) {
            setAnchorEl(null)
            setOpen(false)
            setFieldSelected(null)
        } else {
            setAnchorEl(e.currentTarget)
            setOpen(true)
            setFieldSelected(value)
        }
    }
    return [
        {
            field: 'name',
            headerName: 'Nom',
            flex: 1.4,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {

                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'stockage',
            headerName: `Stockage`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.row.data.stockage > 0 ? "+" : ""} {Math.round(params.row.data.stockage).toString().replace('.', ',')} %</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'incremental',
            headerName: `Incrémental (${unit})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                const incremental = params.row.data.incremental > 0 ? Math.round(params.row.data.incremental).toString().replace('.', ',') : 0

                return params.row.data.incremental ? (
                    <Grid container alignItems="center" ml="0.8em" justifyContent="center">
                        <SecondaryText color={BLACK}>
                            {params.row.data.incremental > 0 ? "+" : ""}
                            {<NumberWithSpace number={incremental} />}
                        </SecondaryText>
                    </Grid >
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        }, {
            field: 'evolUF',
            headerName: unityType === UnityType.UF ? "Evo UF" : "Transactions",
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {

                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.row.data.evolUF ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <ArrowTendency number={params.row.data.evolUF} />
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={params.row.data.evolUF.toFixed(2).toString().replace('.', ',')}
                            />
                            {Units.percent}
                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'cost',
            headerName: `Coût incrémental (€/${unit})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.row.data.cost ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={params.row.data.cost.toFixed(2).toString().replace('.', ',')}
                            />

                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'action',
            headerName: "",
            flex: 0.1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid>
                        <MoreVertIcon id="MoreIcon" sx={{ color: WHITE, cursor: 'pointer' }} onClick={(e) => handleClick(e, params.row)} />
                        <ActionMenu
                            open={open}
                            anchorEl={anchorEl}
                            onClose={(e) => {
                                handleClick(e, params.row)
                            }}
                            children={<ModifyCampaignMenu values={fieldSelected} closeMenu={setOpen} />}
                        />
                    </Grid>)
            },


        }

    ]
} 