import React from 'react'
import { Checkbox, Grid } from '@mui/material'
import { isSelected, toggleChangeFilters } from './FiltersFunction'
import { FiltersData } from './MainFilter'
import { DepthOfData } from '../FilterSelect'
import { SecondaryText } from '../../../ui/Text'
import { BLACK, MATERIAL_BLUE, WHITE } from '../../../ui/Colors'
import { Separator } from '../../../ui/Separator'
import { MenuType } from '../utils'

export const checkboxCSS = {
    minWidth: { xs: '40px', md: '2vw' },
    padding: { xs: '20px', md: '1vw' },
    '& svg': {
        width: { xs: '22px', md: '1.5vw' },
        height: { xs: '22px', md: '2.5vh' },
    },
    mr: '0.3vw',
    ml: '0.5vw',
}

export const FilterComponent = ({
    data,
    filters,
    setFieldValue,
    type,
    search,
    depth,
    activeDepth,
}: {
    data: FiltersData[]
    filters: string[]
    setFieldValue: (field: string, value: string[]) => void
    type: MenuType
    search: string
    depth: number
    activeDepth: number
}) => {
    return (
        <Grid
            sx={{
                ...(activeDepth === DepthOfData.ONE
                    ? {
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        scrollBehavior: 'smooth',
                        maxHeight: '57vh',
                        background: WHITE,
                    }
                    : {
                        padding: '0vh 1vw',
                        overflowX: 'hidden',
                        background: WHITE,
                    }),
            }}
        >
            {data.map((el, index) => {
                let nameWithSearchOccurences = el.name
                    .toLowerCase()
                    .split(search.toLowerCase())
                    .join('$')

                return (
                    <Grid key={index} >
                        <Grid
                            container
                            alignItems="center"
                            sx={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                const values = toggleChangeFilters(
                                    el.level,
                                    el,
                                    filters
                                )
                                setFieldValue(type, values)
                            }}
                        >
                            <Checkbox
                                checked={isSelected(
                                    el,
                                    filters,
                                    el.level,
                                    depth
                                )}
                                sx={checkboxCSS}
                            />
                            <SecondaryText
                                color={BLACK}
                                bold={activeDepth < depth}
                            >
                                {nameWithSearchOccurences
                                    .split('')
                                    .map((el, index) => {
                                        return el === '$' ? (
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: MATERIAL_BLUE,
                                                }}
                                                key={index}
                                            >
                                                {search.toUpperCase()}
                                            </span>
                                        ) : (
                                            el.toUpperCase()
                                        )
                                    })}
                            </SecondaryText>
                        </Grid>
                        {el.children && (
                            <Grid
                                style={{
                                    paddingLeft: '1vw',
                                    paddingBottom:
                                        activeDepth === DepthOfData.ONE
                                            ? 0
                                            : '2vh',
                                }}
                            >
                                {activeDepth === DepthOfData.ONE && (
                                    <Separator mt="0.5vh" mb="0.5vh" />
                                )}
                                <FilterComponent
                                    filters={filters}
                                    data={el.children}
                                    setFieldValue={setFieldValue}
                                    type={type}
                                    search={search}
                                    depth={depth}
                                    activeDepth={activeDepth + 1}
                                />
                            </Grid>
                        )}
                    </Grid>
                )
            })}
        </Grid>
    )
}
