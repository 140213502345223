import { Grid, ImageList, ImageListItem } from '@mui/material'
import React from 'react'
import ChartBlock from '../../components/features/ChartBlock'
import { type Env, EnvStatus, ConnectorType } from '../../redux/user/userTypes'
import { PrimaryText } from '../../components/ui/Text'
import { BLACK } from '../../components/ui/Colors'
import { PrimaryButton } from '../../components/ui/Button'
import { distributorImages } from '../../helpers/distributorsList'
import OtherDistributorIcon from '../../components/features/OtherDistributorIcon'
import { type LastImport } from '../../redux/imports/importsTypes'
import { EnvStatusChip } from '../../components/features/import/EnvStatusChip'

interface OwnProps {
    envList: Env[]
    lastImports: LastImport[]
    handleClick: (Env) => void
    handleClickAdd: (string) => void
}

interface DistributorListTemplateProps {
    title: string
    list: Env[]
    handleClick: (Env) => void
    lastImports: LastImport[]
}
interface DistributorListProps {
    list: Env[]
    handleClick: (Env) => void
    last?: LastImport[]
}

const DistributorList = ({ list, handleClick, last }: DistributorListProps) => {
    return (
        <ImageList cols={5} rowHeight="auto">
            {list.map((item) => {
                const img = distributorImages[item.name]

                return (
                    <ImageListItem
                        key={img}
                        sx={{
                            margin: 'auto',
                            flex: 1,
                            height: '1vh',
                            cursor: 'pointer',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            handleClick(item)
                        }}
                    >
                        {img ? (
                            <img
                                src={`${img}`}
                                srcSet={`${img}`}
                                alt={item.name}
                                loading="lazy"
                                style={{
                                    objectFit: 'contain',
                                    width: '5vw',
                                    height: '5vw',
                                }}
                            />
                        ) : (
                            <OtherDistributorIcon
                                name={item.name}
                                onClick={() => {
                                    handleClick(item)
                                }}
                            />
                        )}
                        <Grid marginTop="2vh" width={'100%'}>
                            <EnvStatusChip
                                pointer
                                status={item.status}
                                name={item.name}
                                last={last}
                                connectorType={item.connectorType}
                            />
                        </Grid>
                    </ImageListItem>
                )
            })}
        </ImageList>
    )
}

const ListHeader = ({ title }: { title: string }) => {
    return <PrimaryText color={BLACK}>{title}</PrimaryText>
}

const DistributorListTemplate = ({
    title,
    list,
    handleClick,
    lastImports,
}: DistributorListTemplateProps) => {
    return (
        <Grid item mb="2vh">
            <ListHeader title={title} />
            <DistributorList
                list={list}
                handleClick={handleClick}
                last={lastImports}
            />
        </Grid>
    )
}
const DisplayDistributor = ({
    envList,
    lastImports,
    handleClick,
    handleClickAdd,
}: OwnProps) => {
    let automatedDistribList = []
    let manualDistribList = []

    envList.forEach((env) =>
        env.connectorType === ConnectorType.AUTOMATED
            ? automatedDistribList.push(env)
            : manualDistribList.push(env)
    )

    return (
        <ChartBlock title="Distributeurs">
            <Grid container direction="column" p="2vh">
                {automatedDistribList.length > 0 && (
                    <DistributorListTemplate
                        title="Connecteurs automatiques"
                        list={automatedDistribList}
                        lastImports={lastImports}
                        handleClick={handleClick}
                    />
                )}
                {manualDistribList.length > 0 && (
                    <DistributorListTemplate
                        title="Imports manuels"
                        list={manualDistribList}
                        handleClick={handleClick}
                        lastImports={lastImports}
                    />
                )}
                <Grid
                    item
                    width="20vw"
                    padding={4}
                    display="flex"
                    alignSelf="flex-end"
                    justifyContent="flex-end"
                >
                    <PrimaryButton onClick={handleClickAdd}>
                        Ajouter distributeurs
                    </PrimaryButton>
                </Grid>
            </Grid>
        </ChartBlock>
    )
}

export default DisplayDistributor
