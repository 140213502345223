import { CardContent, Grid } from '@mui/material'
import React from 'react'
import { PrimaryText, SubTitle } from '../../../../../../components/ui/Text'
import { BLACK, DARK_BLUE } from '../../../../../../components/ui/Colors'
import {
    Top3Warehouses,
    TopCategory,
} from '../../../../../../redux/fsProducts/fsProductsTypes'
import { roundIfNotZero } from '../../../../../../helpers/roundIfNotZero'

export const DataCardDrillDownPromoClients = ({
    top3Warehouses,
    topCategory,
}: {
    top3Warehouses: Top3Warehouses[]
    topCategory: TopCategory[]
}) => {
    return (
        <Grid container flexDirection="row" justifyContent={'space-between'}>
            {top3Warehouses.map((item, index) => {
                const category = topCategory.filter(
                    (category) => category.subSubType === item.subSubType
                )
                return (
                    <CardContent sx={{ border: 1, borderColor: DARK_BLUE }} key={index} >
                        <SubTitle>{item.subSubType}</SubTitle>
                        <PrimaryText color={BLACK} mt={'1vh'}>
                            Top 3 dépôts
                        </PrimaryText>
                        {item.warehouses.map((warehouse, index) => {
                            return (
                                <PrimaryText key={index}>
                                    {`${warehouse.warehouseName
                                        } : ${roundIfNotZero(
                                            warehouse.percentage
                                        )}%`}
                                </PrimaryText>
                            )
                        })}

                        {/*   <PrimaryText color={BLACK} mt={'1vh'}>
                            Top catégorie
                        </PrimaryText>
                        <PrimaryText>{`${
                            category[0].category
                        } : ${roundIfNotZero(
                            category[0].percentage
                        )} %`}</PrimaryText> */}
                    </CardContent>
                )
            })}
        </Grid>
    )
}
