import { Grid } from "@mui/material"
import { DataTableComponent } from "../../../DataTableComponent"
import React, { useState } from "react"
import { DrillDownType } from "../../../../../components/features/drillDowns/ProductsDrillDownContent"
import { GridCellParams } from "@mui/x-data-grid"
import { GBHSuppliersColumns, GBHSuppliersHeadTabColumns } from "../../utils"
import DrillDown from "../../../../../components/features/drillDowns/DrillDown"
import { useAppSelector } from "../../../../../redux/hooks"

export const SuppliersPerRegion = () => {
    const [isDrilldownSuppliersOpen, setDrilldownProductsOpen] = useState(false)
    const [fieldSuppliersSelected, setFieldProductsSelected] =
        useState<GridCellParams<any>>()
    const [productsValue, setProductsValue] = useState(0)
    const { suppliers } = useAppSelector((state) => state.fsProducts)

    const handleCloseProducts = () => {
        setDrilldownProductsOpen(false)
    }

    return (
        <Grid height={'100%'}>
            <Grid height="45vh" mb="2vh">
                <DataTableComponent
                    rows={suppliers.regionsList}
                    columns={GBHSuppliersHeadTabColumns("region")}
                    getRowId={(row) => row.region}
                    hasDrillDown={false}
                />
            </Grid>
            <Grid height="45vh">
                <DrillDown
                    type={DrillDownType.SUPPLIERSGBH}
                    open={isDrilldownSuppliersOpen}
                    title={fieldSuppliersSelected && `${fieldSuppliersSelected.row.supplier}`}
                    value={productsValue}
                    setValue={setProductsValue}
                    row={fieldSuppliersSelected}
                    handleClose={handleCloseProducts}
                />
                <DataTableComponent
                    type={DrillDownType.SUPPLIERSGBH}
                    setOpen={setDrilldownProductsOpen}
                    setFieldSelected={setFieldProductsSelected}
                    setValue={setProductsValue}
                    rows={suppliers.suppliersList ? suppliers.suppliersList : []}
                    columns={GBHSuppliersColumns("region")}
                    getRowId={(row) => row.id}
                />
            </Grid>
        </Grid >
    )
}