import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material'
import { Field, Form, FormikBag, FormikProps, withFormik } from 'formik'
import React, { useState } from 'react'
import { array, object, string } from 'yup'
import { PrimaryButton } from '../../../../../components/ui/Button'
import { EnhancedField, TextInput } from '../../../../../components/ui/Inputs'
import { StepperData } from '../../../../../redux/stepper/stepperTypes'
import { Inputs } from './SignUpAccount'
import { ErrorMessageForm, PrimaryText } from '../../../../../components/ui/Text'
import { BLACK } from '../../../../../components/ui/Colors'
import { StepContentComponent } from '../stepper/Stepper'
import { checkboxCSS } from '../../../../../components/features/filters/selects/FilterComponent'

interface FormValues {
    useCases: string[]
    otherUseCases: string
    job: string
}

interface FormProps {
    onSubmit: (values: FormValues) => void
    initialValues: FormValues
}

const inputs: Inputs[] = [{ type: 'text', name: 'job', label: 'Poste' }]
enum UseCases {
    USECASE1 = 'Visites commerciales avec les points de vente en région',
    USECASE2 = 'Analyse de la segmentation des utilisateurs finaux par référence et/ou par dépôt',
    USECASE3 = 'Identification des leviers de croissance (Marketing, Category Management)',
    USECASE4 = 'Gestion des promotions',
    USECASE5 = 'Management des équipes commerciales',
    USECASE6 = 'Négociations commerciales avec les distributeurs',
}

export const useCasesArray: (UseCases | string)[] = [
    UseCases.USECASE1,
    UseCases.USECASE2,
    UseCases.USECASE3,
    UseCases.USECASE4,
    UseCases.USECASE5,
    UseCases.USECASE6,
]

const PureSignUpJobForm = (
    props: FormikProps<FormValues> & FormProps
): JSX.Element => {
    const [inputText, setInputText] = useState<string>('')

    const handleChangeText = (event: React.FormEvent<HTMLInputElement>) => {
        setInputText(event.currentTarget.value)
    }

    const handleChange = (useCase: string) => {
        const isInclude = props.values.useCases.includes(useCase)

        if (isInclude) {
            props.setFieldValue(
                'useCases',
                props.values.useCases.filter((el) => el !== useCase)
            )
        } else {
            props.setFieldValue('useCases', [...props.values.useCases, useCase])
        }
    }
    const handleAddButton = ({ otherUseCases }: { otherUseCases: string }) => {
        const isInclude = props.values.useCases.includes(otherUseCases)
        if (!isInclude) {
            useCasesArray.push(otherUseCases)
            handleChange(otherUseCases)
        }
        setInputText('')
    }
    return (
        <Form>
            <Grid container direction="column">
                <PrimaryText color={BLACK} bold>
                    Dites-nous en plus sur votre activité professionnelle !
                </PrimaryText>
                {inputs.map((input, index) => (
                    <EnhancedField
                        type={input.type}
                        name={input.name}
                        label={input.label}
                        autoFocus={index === 0}
                        margin="normal"
                        component={TextInput}
                    />
                ))}
                <Grid pt="1.5vh">
                    <PrimaryText color={BLACK} bold>
                        Vos Missions
                    </PrimaryText>
                    <Grid container alignItems="flex-start" direction="column">
                        {useCasesArray.map((input, index) => {
                            const isInclude =
                                props.values.useCases.includes(input)

                            return (
                                <Grid
                                    pb="1vh"
                                    sx={{
                                        '& .MuiTypography-body1': {
                                            fontSize: {
                                                xs: '16px',
                                                md: '1.7vmin',
                                            },
                                        },
                                    }}
                                    key={index}
                                >
                                    <FormControlLabel
                                        sx={{
                                            alignItems: 'center',
                                            fontWeight: 400,
                                            fontFamily: [
                                                'Cabin',
                                                'sans-serif',
                                            ].join(','),
                                            '& span': {
                                                fontSize: {
                                                    xs: '16px',
                                                    md: '1.7vmin',
                                                },
                                            },
                                        }}
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    handleChange(input)
                                                }
                                                defaultChecked={isInclude}
                                                sx={checkboxCSS}
                                            />
                                        }
                                        label={input}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        marginTop="1vh"
                        spacing={3}
                    >
                        <Grid item md={8}>
                            <Field
                                label="Autre(s) mission(s)"
                                type="text"
                                value={inputText}
                                name="otherUseCases"
                                onChange={handleChangeText}
                                component={TextInput}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <PrimaryButton
                                disabled={inputText.length < 1}
                                type="button"
                                onClick={() => {
                                    handleAddButton({
                                        otherUseCases: inputText,
                                    })
                                }}
                            >
                                Ajouter
                            </PrimaryButton>
                        </Grid>
                    </Grid>

                    <Box mt="2vh">
                        <ErrorMessageForm message={props.errors.useCases} />
                    </Box>
                </Grid>
                <Box mt="4vh" width="100%">
                    <PrimaryButton fullWidth type="submit">
                        Suivant
                    </PrimaryButton>
                </Box>
            </Grid>
        </Form>
    )
}

const ControlledSignUpJobForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props: FormProps) => ({
        useCases: props.initialValues.useCases,
        otherUseCases: props.initialValues.otherUseCases
            ? props.initialValues.otherUseCases
            : '',
        job: props.initialValues.job,
    }),
    validationSchema: () => {
        return object().shape({
            useCases: array()
                .of(string())
                .min(1, `Merci de cocher ou saisir au moins une mission`),
            job: string().required('Le champs Poste est obligatoire'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureSignUpJobForm)

const SignUpJob = ({
    getData,
    updateData,
    handleNext,
}: StepContentComponent) => {
    const initialValues = getData() as unknown as FormValues

    const handleSubmit = (values: FormValues) => {
        updateData(values as unknown as StepperData)
        handleNext()
    }

    return (
        <Grid container direction="column">
            <ControlledSignUpJobForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
        </Grid>
    )
}

export default SignUpJob
