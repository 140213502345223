import { DialogContent, Grid } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import React, { useState, useCallback, useEffect } from 'react'
import { date, object, ref } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import FilterSelect from '../../filters/FilterSelect'
import FilterCalendar from '../../filters/FilterCalendar'
import { PrimaryText } from '../../../ui/Text'
import { DialogFooter } from '../../Dialog/BasicFooterDialog'
import { type StepConfig } from '../../../../redux/views/viewsTypes'
import {
    closeViewCreator,
    updateData,
} from '../../../../redux/views/viewsSlice'
import {
    getDefaultPeriod,
    getPlaceholderDates,
    monthAsArray,
} from '../../../../helpers/months'
import { EnvType } from '../../../../redux/user/userTypes'
import { formateDateToString } from '../../utils/formateDateToString'
import { FiltersItem, MenuType, getFiltersConfig } from '../../filters/utils'
import InventoryIcon from '@mui/icons-material/Inventory'
import { trashRetailFilters } from '../../../../redux/filters/filtersSlice'
import { differenceInMonths } from '../../GlobalPages/Private/Period'

interface OwnProps {
    config: StepConfig
    step: number
}

export interface RetailFormValues {
    startDate: Date
    endDate: Date
    products: string[]
    stores: string[]
    distributionChannels: string[]
    min: Date
    max: Date
}

interface FormProps {
    activeValues: RetailFormValues
    config: StepConfig
    step: number
    onSubmit: (values: RetailFormValues) => void
    filtersConfig: FiltersItem[]
}

const PureViewFilterStepForm = ({
    setFieldValue,
    submitForm,
    filtersConfig,
    ...props
}: FormikProps<RetailFormValues> & FormProps) => {
    const [modified, setModified] = useState(false)
    const periodByDefault = getDefaultPeriod()
    const [printedPeriod, setPrintedPeriod] = useState<string | null>(
        periodByDefault
    )

    useEffect(() => {
        if (props.values.startDate && props.values.endDate) {
            const period = getPlaceholderDates(
                props.values.startDate,
                props.values.endDate
            )
            setPrintedPeriod(period)
        }
    }, [props.values])

    return (
        <Form style={{ width: '100%' }}>
            <DialogContent>
                <Grid mt="5vh" mb="5vh">
                    <Grid
                        container
                        style={{
                            padding: 4,
                        }}
                    >
                        <PrimaryText width="30%">Mois et année : </PrimaryText>
                        <Grid sx={{ flex: 1, width: '100%' }}>
                            <FilterCalendar
                                isDialogFilter
                                isModified={modified}
                                placeholder={printedPeriod}
                                setFieldValue={setFieldValue}
                                setModified={setModified}
                                start={props.values.startDate}
                                end={props.values.endDate}
                                errorEndDate={props.errors.endDate as string}
                                errorStartDate={
                                    props.errors.startDate as string
                                }
                            />
                        </Grid>
                    </Grid>
                    {filtersConfig.map((filter, index) => (
                        <Grid
                            container
                            style={{
                                padding: 4,
                            }}
                            key={index}
                        >
                            <PrimaryText width="30%">
                                {filter.placeholder} :{' '}
                            </PrimaryText>
                            <FilterSelect
                                name={filter.name}
                                icon={filter.icon}
                                placeholder={filter.placeholder}
                                type={filter.type}
                                values={props.values[filter.name]}
                                setFieldValue={setFieldValue}
                                key={filter.id}
                                env={EnvType.RETAIl}
                                isDialogFilter
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={
                    props.step === 0
                        ? props.config.handleReset
                        : props.config.handlePrevious
                }
                leftButtonText={props.step === 0 ? 'ANNULER' : 'PRÉCÉDENT'}
                rightButtonFunc={async () => {
                    await submitForm()
                }}
                rightButtonText={
                    props.step === props.config.stepsNumber - 1
                        ? 'CRÉER MA VUE'
                        : 'SUIVANT'
                }
            />
        </Form>
    )
}

const ControlledFsViewsFiltersForm = withFormik<FormProps, RetailFormValues>({
    mapPropsToValues: (props) => ({
        startDate: props.activeValues.startDate,
        endDate: props.activeValues.endDate,
        products: props.activeValues.products,
        stores: props.activeValues.stores,
        distributionChannels: props.activeValues.distributionChannels,
        min: props.activeValues.min,
        max: props.activeValues.max
    }),
    enableReinitialize: true,
    validationSchema: (props) => {
        const startMonth = props.activeValues.min.getMonth()
        const startYear = props.activeValues.min.getFullYear()
        const endMonth = props.activeValues.max.getMonth()
        const endYear = props.activeValues.max.getFullYear()

        return object().shape({
            startDate: date()
                .typeError('Entrez une date valide')
                .min(
                    props.activeValues.min,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de début'),
            endDate: date()
                .typeError('Entrez une date valide')
                .min(
                    ref('startDate'),
                    'Sélectionnez une date plus ancienne que celle de début'
                )
                .max(
                    props.activeValues.max,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de fin')
                .test(
                    'is-within-12-months',
                    'Vous ne pouvez pas sélectionner plus de 12 mois consécutifs.',
                    function (endDate) {
                        const startDate = props.dates && props.dates.startDate

                        if (startDate && endDate) {
                            const monthsDifference = differenceInMonths(startDate, endDate);
                            return monthsDifference <= 11;
                        }
                        return true; // Si les dates ne sont pas définies, on passe.
                    }
                ),
        })
    },
    handleSubmit: (
        values: RetailFormValues,
        bag: FormikBag<FormProps, RetailFormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureViewFilterStepForm)

const RetailViewsFiltersForm = ({ step, config }: OwnProps) => {
    const dispatch = useAppDispatch()
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const { retailFilters } = useAppSelector((state) => state.filters)
    const { env } = useAppSelector((state) => state.global)
    const [filtersConfig, setFiltersConfig] = useState<FiltersItem[]>([
        {
            id: 0,
            name: 'products',
            icon: <InventoryIcon />,
            placeholder: 'Références',
            type: MenuType.PRODUCTS,
        },
    ])

    useEffect(() => {
        const keysFilters = retailFilters ? Object.keys(retailFilters) : []
        const config = getFiltersConfig(keysFilters, env.name)
        setFiltersConfig(config)
    }, [retailFilters])

    const _trashRetailFilters = useCallback(
        () => dispatch(trashRetailFilters()),
        [dispatch]
    )

    const _closeViewCreator = useCallback(
        () => dispatch(closeViewCreator()),
        [dispatch]
    )

    const _updateData = useCallback(
        ({
            products,
            startDate,
            endDate,
            stores,
            distributionChannels,
            min,
            max
        }: {
            startDate: string
            endDate: string
            products: string[]
            stores: string[]
            distributionChannels: string[]
            min: string,
            max: string
        }) =>
            dispatch(
                updateData({
                    data: {
                        filters: {
                            products,
                            startDate,
                            endDate,
                            stores,
                            distributionChannels,
                            min,
                            max
                        },
                    },
                })
            ),
        [dispatch]
    )

    const handleSubmit = (values: RetailFormValues) => {
        const formatedDate = formateDateToString(
            values.startDate,
            values.endDate
        )
        _updateData({
            products: values.products,
            startDate: formatedDate.start,
            endDate: formatedDate.end,
            stores: values.stores,
            distributionChannels: values.distributionChannels,
            min: activeRetailFilters.min,
            max: activeRetailFilters.max
        })
        config.handleSubmit()
        _trashRetailFilters()
        _closeViewCreator()
    }

    const activeValues = {
        startDate: new Date(activeRetailFilters.startDate),
        endDate: new Date(activeRetailFilters.endDate),
        products: activeRetailFilters.products,
        stores: activeRetailFilters.stores,
        distributionChannels: activeRetailFilters.distributionChannels,
        min: new Date(activeRetailFilters.min),
        max: new Date(activeRetailFilters.max)
    }

    activeValues.startDate.setHours(0, 0, 0)
    activeValues.endDate.setHours(0, 0, 0)
    activeValues.min.setHours(0, 0, 0)
    activeValues.max.setHours(0, 0, 0)

    return (
        <ControlledFsViewsFiltersForm
            config={config}
            step={step}
            onSubmit={handleSubmit}
            activeValues={activeValues}
            filtersConfig={filtersConfig}
        />
    )
}

export default RetailViewsFiltersForm
