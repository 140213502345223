import { Dialog, DialogContent } from '@mui/material'
import React, { useState, useCallback } from 'react'
import { DARK_BLUE, MEDIUM_GREY } from '../ui/Colors'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { useAppDispatch } from '../../redux/hooks'
import { PrimaryText } from '../ui/Text'
import { createProductsTourLog } from '../../redux/product-tour/productsTourSlice'
import { PRODUCT_TOUR_MESSAGES } from '../../redux/product-tour/productsTourType'
import ProductTourImg from '../../assets/GIF Filters.gif'
import { DialogHeader } from './Dialog/BasicDialog'
import { DialogFooter } from './Dialog/BasicFooterDialog'

const images = [
    {
        label: (
            <span>
                <span>
                    Un chargement trop long lorsque vous filtriez sur la plateforme ?
                    Ces nouveaux filtres vous offrent une <b>navigation plus fluide</b>. <br />
                    À tester dès à présent !
                </span>
            </span>
        ),
        imgPath: ProductTourImg,
    },
]

function SwipeableTextMobileStepper() {
    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = images.length

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                width: '100%',
                textAlign: 'center',
            }}
        >
            {images.map((step, index) => (
                <Box
                    component="img"
                    sx={{
                        display: 'block',
                        overflow: 'hidden',
                        width: '100%',
                        borderRadius: '5px',
                    }}
                    src={step.imgPath}
                    key={index}
                />
            ))}
            <PrimaryText mt="2vh" mb="1vh" color={DARK_BLUE}>
                {images[activeStep].label}
            </PrimaryText>
            {images.length > 1 && (
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                        </Button>
                    }
                />
            )}
        </Box>
    )
}

export const ProductTourDialog = ({
    title,
    productTourId,
}: {
    title: string
    productTourId: string
}) => {
    const [open, setOpen] = useState(true)
    const dispatch = useAppDispatch()
    const _createProductsTourLog = useCallback(
        async (message: string, productTourId: string) => {
            await dispatch(createProductsTourLog(message, productTourId))
        },
        [dispatch]
    )

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            onClose={() => {
                setOpen(false)
            }}
            open={open}
            PaperProps={{
                sx: { borderRadius: '1em', width: '780px', padding: '1em' },
            }}
        >
            <DialogHeader title={title} setOpen={() => setOpen(false)} xl />
            <DialogContent sx={{ borderBottom: `solid ${MEDIUM_GREY} 1px` }}>
                <SwipeableTextMobileStepper />
            </DialogContent>
            <DialogFooter
                leftButtonFunc={() => {
                    setOpen(false)
                    _createProductsTourLog(
                        PRODUCT_TOUR_MESSAGES.BE_CONCTACTED,
                        productTourId
                    )
                }}
                leftButtonText="Être contacté.e"
                rightButtonFunc={() => {
                    setOpen(false)
                    _createProductsTourLog(
                        PRODUCT_TOUR_MESSAGES.OK,
                        productTourId
                    )
                }}
                rightButtonText="Ok"
            />
        </Dialog>
    )
}
