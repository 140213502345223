import React from 'react'
import { Grid } from '@mui/material'
import FsKpis, { ConfigKPIs } from './Dashboard/FsKpis'
import FsSegmentation from './Dashboard/FsSegmentation'
import { HistogramFS } from './Dashboard/HistogramFS'
import { useAppSelector } from '../../../../redux/hooks'
import {
    EnvName,
    FsGBHKPIs,
} from '../../../../redux/user/userTypes'
import DashboardDataGrid from './Dashboard/DashboardDataGrid'
import FsGBHTopFlop from './Dashboard/GBH/FsGBHHistogram'
import { Units } from '../../../../redux/global/globalTypes'
import { GridColDef } from '@mui/x-data-grid'

export const DashboardGBHPage = ({
    KPIsConfig,
    dashboardGridDataTitle,
    segmentationChartTitle,
    warehousesConfig
}: {
    KPIsConfig: ({
        KPIs,
        envName,
    }: {
        KPIs: FsGBHKPIs
        envName: EnvName
    }) => ConfigKPIs[]
    warehousesConfig: GridColDef[]
    dashboardGridDataTitle: string
    segmentationChartTitle: string
}) => {
    const { name } = useAppSelector((state) => state.global.env)
    const dashboardGridData = useAppSelector((state) => state.global.fsDashboardData.warehouses)
    const dashboardGridDataLink = `/food-service/${name}/pointsdevente`

    return (
        <Grid container direction="column" wrap="nowrap" height="90vh" p="1vw">
            <FsKpis KPIsConfig={KPIsConfig} />
            <Grid
                container
                direction="column"
                wrap="nowrap"
                justifyContent="flex-start"
                pt="1vw"
                sx={{ height: '80vh' }}
            >
                <Grid container wrap="nowrap"
                    sx={{ height: '40%' }}>
                    <Grid flex={1.5}>
                        <FsGBHTopFlop />
                    </Grid>
                    <Grid flex={2} paddingLeft={2}>
                        <HistogramFS title={`Total des ventes JPB par semestre (${Units.uvc})`} />
                    </Grid>
                </Grid>
                <Grid container wrap="nowrap" pt="1vw" sx={{ height: '60%' }}>
                    <Grid
                        item
                        flex={1.5}
                        sx={{ height: '100%', width: '14vw' }}
                    >
                        <Grid item flex={1.5} height="100%">
                            <DashboardDataGrid
                                columns={warehousesConfig}
                                drilldown={false}
                                data={dashboardGridData}
                                title={dashboardGridDataTitle}
                                link={dashboardGridDataLink}
                                envName={name}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        flexWrap="nowrap"
                        direction="column"
                        flex={2}
                        pl="1vw"
                        sx={{ height: '100%' }}
                    >
                        <FsSegmentation
                            title={segmentationChartTitle}
                            withLegend={false}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
