import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { BLACK, DARK_BLUE } from './Colors'
import { UsersRole } from '../../redux/user/userTypes'
import { PrimaryText } from './Text'

interface OwnProps extends RadioGroupProps {
    label: string
    defaultCheck: string
    values: {
        label: string
        value: UsersRole
    }[]
}
export const RadioButtonsGroup = ({
    label,
    defaultCheck,
    values,
    ...props
}: OwnProps) => {
    return (
        <FormControl>
            <FormLabel
                sx={{
                    fontWeight: 'bold',
                    color: DARK_BLUE,
                    mb: '1vh',
                    fontSize: '1.5vh',
                }}
            >
                {label}
            </FormLabel>
            <RadioGroup
                defaultValue={defaultCheck}
                name="radio-buttons-group"
                {...props}
                sx={{
                    '& .MuiSvgIcon-root': {
                        height: '2vh',
                        width: '2vh',
                        marginRight: 1,
                    },
                }}
            >
                {values.map((value, index) => (
                    <FormControlLabel
                        value={value.value}
                        control={<Radio />}
                        label={
                            <PrimaryText color={BLACK}>
                                {value.label}
                            </PrimaryText>
                        }
                        key={index}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}
